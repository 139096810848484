//
// Quick Search Widget
//
.QuickSearchIcon {
	max-height:48px;
  .LandingPage & {
    height: 48px;
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }
}
.navbar {
  .quick-search {
    flex: 0 0 333px;
  }
}
.card-quick-search {
	.quick-search-component {
		.quick-search {
			max-width: 500px;
		}
	}
  &.titleless {
    @extend .card-normalize-styles;
  }

  &:not(.titleless) {
    .not-menu-bar-mode {
      padding-bottom: ($spacer / 2);
    }
  }

  .no-admin-mode & {
    &.titleless {
      .not-menu-bar-mode {
        padding: 0;
      }
    }
  }

  .no-admin-mode & {
    &.titleless {
      .not-menu-bar-mode {
        padding: 0;
      }
    }
  }

  //.LandingPage.admin-mode & {
  //
  //}
  .LandingPage.no-admin-mode & {
    position: fixed;
    z-index: 5;
    top: $spacer * 5;
    right: $spacer;
    left: $spacer;
    margin-bottom: 0;
    border: none;
    background-color: transparent;

    .card-body {
      padding: $spacer !important;
    }

    .input-group-btn {
      display: none;
    }

    .QuickSearchContent {
      width: 100%;
    }

    .quick-search-component {
      max-width: 600px;
      margin-right: auto;
      margin-left: auto;

      .form-control {
        @extend .form-control-lg;
      }
    }

    .form-inline {
      .input-group {
        flex: 0 0 100%;
        width: auto;
        max-width: 100%;
      }
    }
  }
}

.quick-search {
  .QSTextBoxItem {
    max-width: 230px;
    overflow: hidden;
  }
  &.form-control-pseudo {
    border: none !important;
    border-radius: .5rem;
    //background: rgba($black, .07);
    background-color: $white;
    box-shadow: 0 2px 2px 0 rgba($black, 0.16), 0 0 0 1px rgba($black, 0.08);

    .form-control {
      background-color: transparent;
    }

    //transition: box-shadow 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
    @include mat-elevation-transition();

    &:hover,
    &.form-control-has-focus {
      background-color: $white;
      //border: 1px solid rgba(0,0,0,0.12);
      //box-shadow: 0 1px 1px rgba(0,0,0,0.24);
      box-shadow: 0 3px 8px 0 rgba($black, 0.2), 0 0 0 1px rgba($black, 0.08);
      .btn-quick-search-options {
        opacity: 1;
      }
    }
  }
}

.btn-quick-search-options {
  opacity: 0;
}

//.QuickSearch {
//  display: flex;
//  @extend .align-items-center;
//  @extend .justify-content-center;
//
//  .DictionaryBackedAutoCompleteItemizedTextBox {
//    flex-grow: 3;
//    width: auto;
//    margin-left: 4px;
//  }
//
//  .Expandable {
//    z-index: 1;
//  }
//
//  .SearchAlternativesButton {
//    position: relative;
//    margin: 0;
//  }
//  .ExpandableText {
//    margin: 0;
//  }
//}
//
//.QuickSearchAlternativesWidgetMode {
//  position: absolute;
//  left: 20px;
//  z-index: 10;
//  width: 240px;
//  max-height: 300px;
//  margin-top: 30px !important;
//  margin-left: 2px !important;
//  overflow-x: hidden;
//  overflow-y: auto;
//}
//
//
//
//.QuickSearchButton {
//  .normal & {
//    margin-left: 4px;
//  }
//}
//
//.QuickSearchContent {
//  max-height: 300px;
//  background-color: $white;
//}
//
