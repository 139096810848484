//
// Media
//
$media-comment-bg: #e5e5ea;
//.media-body {
//  .badge {
//    position: relative;
//    top: -1px;
//  }
//}
.asset-view-comment-list {
  min-height: 200px !important;
  max-height: 40vh;
}

.media-comment {
  position: relative;
  padding: 0.15rem $list-group-item-padding-x;
  //max-width: 90%;
  .user-avatar {
    align-self: flex-end;
  }

  &:hover {
    .media-comment-response {
      transform: scale(1);
      opacity: 1;
    }
  }

  &:last-child {
    margin-bottom: 1rem;
  }
  transition: background-color 0.5s ease-out;
}

.media-comment-session-user {
  .media-comment-body {
    background-color: theme-color("primary");

    &,
    * {
      color: $white;
    }
  }

  .user-avatar,
  .media-comment-user-name {
    display: none;
  }

  .CommentLabel {
    display: flex;
    align-content: flex-end;
    flex-direction: column;
  }
}

.media-comment-body {
  font-size: $font-size-sm;
  flex: 0 0 auto;
  width: auto;
  min-width: 34px;
  max-width: 100%;
  min-height: 34px;
  padding: 0.2rem 0.5rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  border-radius: 0.6rem;
  background-color: $media-comment-bg;

  .gwt-HTML {
    max-width: inherit;
  }
}

.media-comment-meta {
  font-size: ($font-size-base * 0.8);
  display: block;
  color: $text-muted;
}

.media-comment-user-name {
  margin-bottom: 0.25rem;
}

.media-comment-response {
  transition: $swift-ease-out;
  transform: scale(0);
  opacity: 0;
}

.media-widget-box-summary {
  img {
    @extend .card-img-top;
  }
}

//.media-widget-box {
//  //padding-bottom: $spacer;
//  //margin-bottom: $spacer;
//  //border-bottom: $card-border-width solid $card-border-color;
//  position: relative;
//  padding: $list-group-item-padding-y $list-group-item-padding-x;
//  // Place the border on the list items and negative margin up for better styling
//  margin-bottom: -$list-group-border-width;
//  border-top: $list-group-border-width solid $list-group-border-color;
//  width: 100%;
//
//  &:first-child {
//    border-top: 0;
//  }
//
//  .embed-responsive {
//    transition: transform .135s cubic-bezier(0, 0, .2, 1);
//  }
//
//  &.active {
//    .embed-responsive {
//      transform: scale(.9);
//    }
//  }
//
//}
//
//.media-widget-box-summary {
//  padding: 0;
//
//  .media-widget-title {
//    line-height: 1.25;
//  }
//}
//
//.media-widget-box-icon {
//  position: absolute;
//  top: -6px;
//  left: -2px;
//  z-index: 2;
//  opacity: 0;
//  font-size: 1.65rem;
//  transform: scale(.5);
//  transition: all .135s cubic-bezier(0, 0, .2, 1);
//
//  &::after {
//    content: "";
//    @include size(18px);
//    border-radius: 5rem;
//    position: absolute;
//    z-index: -1;
//    background-color: $white;
//    top: 4px;
//    left: 4px;
//  }
//
//  .media-widget-box.active & {
//    color: theme-color("success");
//    opacity: 1;
//    transform: scale(1);
//
//    &::after {
//      opacity: 1;
//      @extend .wf-5;
//    }
//  }
//
//}
//
//.media-widget-box-summary {
//  .media-widget-box-icon {
//    opacity: 1;
//    transform: none;
//    font-size: $default-icon-font-size;
//    color: theme-color("success");
//    top: -11px;
//    left: -9px;
//
//
//    &::after {
//
//      @include size(14px);
//    }
//
//    .position-relative {
//      z-index: 0;
//    }
//  }
//}
//
//
//
//
//
.widget-box-full {
  border-bottom: $list-group-border-width solid $list-group-border-color;

  &:last-child,
  &:only-child {
    border-bottom: none;
  }

  .d-block-widget-active {
    display: none;
  }

  .d-none-widget-active {
    display: block;
  }

  &.active {
    .d-block-widget-active {
      display: block;
    }

    .d-none-widget-active {
      display: none;
    }
  }
}

.btn-widget-box-icon {
  position: absolute;
  z-index: 2;
  top: -5px;
  left: 5px;
}

.media-widget-box-summary {
  .d-block-widget-active {
    display: block;
  }

  .d-none-widget-active {
    display: none;
  }
}

//
//
//.ViewLogPanel {
//  &,
//  .x-grid-panel,
//  .x-grid3,
//  .x-grid3-scroller {
//    width: 100% !important;
//  }
//
//}
//
//.comment_panel {
//  min-height: 50px;
//  padding: 4px;
//  margin: 4px 2px;
//
//  transition: background-color .2s ease-out;
//
//  &:before {
//    position: absolute;
//    top: 0;
//    bottom: 0;
//    left: 0;
//    width: 2px !important;
//    content: "";
//    background-color: $color_la_rioja_approx;
//    transition: width .1s ease-in-out;
//  }
//
//  .buttonHolder {
//    position: absolute;
//    right: 3px;
//    bottom: 3px;
//    display: none;
//    div {
//      @extend .d-inline-block;
//      margin-left: 3px;
//    }
//  }
//  .userIcon {
//    top: 5px;
//    left: 8px;
//  }
//
//  &:hover .buttonHolder {
//    display: inherit;
//  }
//}
//
//.annotation_panel {
//
//
//  &:before {
//    position: absolute;
//    top: 0;
//    bottom: 0;
//    left: 0;
//    width: 2px !important;
//    content: "";
//    background-color: theme-color("primary");
//    transition: width .1s ease-in-out;
//  }
//  .buttonHolder {
//    position: absolute;
//    right: 3px;
//    bottom: 3px;
//    display: none;
//    div {
//      @extend .d-inline-block;
//      margin-left: 3px;
//    }
//  }
//  .userIcon {
//    top: 5px;
//    left: 8px;
//  }
//  &.video_annotation_active {
//    background-color: $color_endeavour_40_approx;
//  }
//  &:hover .buttonHolder {
//    display: inherit;
//  }
//  &.thickborder:before {
//    width: 4px !important;
//  }
//  &.annotation_hovered:before {
//    width: 6px !important;
//  }
//}
//
