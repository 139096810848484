//
// Pantone
//
.btn-chip-pantone {
  position: relative;
  padding-left: $spacer * 2;

  .pantone-color-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 29px;
    border: 1px solid transparent;

    .embed-responsive-item {
      @include border-radius(5em);
    }
  }
}

.list-group-item-pantone {
  .pantone-color-container {
    width: $spacer * 2;
    margin-right: 0.5rem;
    border: 1px solid transparent;

    &::after {
      position: absolute;
      z-index: 1;
      @include size($spacer * 1.5);
      right: -$spacer;
      bottom: -$spacer;
      content: "";
      transform: rotate(-45deg);
      opacity: 0;
      background-color: $white;
    }
  }

  .pantone-color-body {
    font-size: $font-size-sm;
  }

  .pantone-label {
    opacity: 0.8;
  }

  &.active {
    color: $list-group-action-color;
    border-color: $component-active-border-light;
    background-color: $component-active-bg-light;

    .pantone-color-container {
      @include mat-elevation(5);
      border-color: $white;

      &::after {
        opacity: 1;
      }
    }
  }
}

.card-pantone {
  @include border-radius(0);
  border-color: transparent;

  .pantone-color-body {
    line-height: $btn-line-height-sm;
    padding: $btn-padding-y-sm $btn-padding-x-sm;
  }

  .pantone-label {
    font-size: 11px;
    line-height: 1;
    display: block;
    width: 100%;
    color: $md-gray-800;
  }

  .pantone-color {
    font-size: 12px;
    font-weight: bold !important;
    display: block;
    width: 100%;
    margin: 0 !important;
    letter-spacing: -0.03rem;
  }
}
