@mixin enter-transform($placement, $animation) {
  @if ($animation == "perspective") {
    @if ($placement == "top") {
      transform: translateY(-10px) rotateX(0);
    } @else if ($placement == "bottom") {
      transform: translateY(10px) rotateX(0);
    } @else if ($placement == "left") {
      transform: translateX(-10px) rotateY(0);
    } @else if ($placement == "right") {
      transform: translateX(10px) rotateY(0);
    }
  } @else if ($animation == "fade") {
    @if ($placement == "top") {
      transform: translateY(-10px);
    } @else if ($placement == "bottom") {
      transform: translateY(10px);
    } @else if ($placement == "left") {
      transform: translateX(-10px);
    } @else if ($placement == "right") {
      transform: translateX(10px);
    }
  } @else if ($animation == "scale") {
    @if ($placement == "top") {
      transform: translateY(-10px) scale(1);
    } @else if ($placement == "bottom") {
      transform: translateY(10px) scale(1);
    } @else if ($placement == "left") {
      transform: translateX(-10px) scale(1);
    } @else if ($placement == "right") {
      transform: translateX(10px) scale(1);
    }
  }
}

@mixin leave-transform($placement, $animation) {
  @if ($animation == "perspective") {
    @if ($placement == "top") {
      transform: translateY(0) rotateX(90deg);
    } @else if ($placement == "bottom") {
      transform: translateY(0) rotateX(-90deg);
    } @else if ($placement == "left") {
      transform: translateX(0) rotateY(-90deg);
    } @else if ($placement == "right") {
      transform: translateX(0) rotateY(90deg);
    }
  } @else if ($animation == "shift-away") {
    @if ($placement == "top") {
      transform: translateY(0);
    } @else if ($placement == "bottom") {
      transform: translateY(0);
    } @else if ($placement == "left") {
      transform: translateX(0);
    } @else if ($placement == "right") {
      transform: translateX(0);
    }
  } @else if ($animation == "scale") {
    @if ($placement == "top") {
      transform: translateY(0) scale(0);
    } @else if ($placement == "bottom") {
      transform: translateY(0) scale(0);
    } @else if ($placement == "left") {
      transform: translateX(0) scale(0);
    } @else if ($placement == "right") {
      transform: translateX(0) scale(0);
    }
  } @else if ($animation == "shift-toward") {
    @if ($placement == "top") {
      transform: translateY(-20px);
    } @else if ($placement == "bottom") {
      transform: translateY(20px);
    } @else if ($placement == "left") {
      transform: translateX(-20px);
    } @else if ($placement == "right") {
      transform: translateX(20px);
    }
  }
}

@mixin backdrop-transform-enter($placement) {
  $scale: 5.5;
  @if ($placement == "top") {
    transform: scale($scale) translate(-50%, 25%);
  } @else if ($placement == "bottom") {
    transform: scale($scale) translate(-50%, -125%);
  } @else if ($placement == "left") {
    transform: scale($scale) translate(33%, -50%);
  } @else if ($placement == "right") {
    transform: scale($scale) translate(-133%, -50%);
  }
}

@mixin backdrop-transform-leave($placement) {
  @if ($placement == "top") {
    transform: scale(1) translate(-50%, 25%);
  } @else if ($placement == "bottom") {
    transform: scale(1) translate(-50%, -125%);
  } @else if ($placement == "left") {
    transform: scale(1.5) translate(33%, -50%);
  } @else if ($placement == "right") {
    transform: scale(1.5) translate(-133%, -50%);
  }
}

@mixin arrow($placement, $width, $color) {
  @if ($placement == "top") {
    border-top: $width solid $color;
    border-right: $width solid transparent;
    border-left: $width solid transparent;
  } @else if ($placement == "bottom") {
    border-bottom: $width solid $color;
    border-right: $width solid transparent;
    border-left: $width solid transparent;
  } @else if ($placement == "left") {
    border-left: $width solid $color;
    border-top: $width solid transparent;
    border-bottom: $width solid transparent;
  } @else if ($placement == "right") {
    border-right: $width solid $color;
    border-top: $width solid transparent;
    border-bottom: $width solid transparent;
  }
}

@mixin arrow-position($placement, $width, $isRoundArrow) {
  @if ($placement == "top") {
    bottom: -$width;
  } @else if ($placement == "bottom") {
    top: -$width;
  }
  @if ($isRoundArrow) {
    @if ($placement == "left") {
      right: -$width * 2;
    } @else if ($placement == "right") {
      left: -$width * 2;
    }
  } @else {
    @if ($placement == "left") {
      right: -$width;
    } @else if ($placement == "right") {
      left: -$width;
    }
  }
}

@mixin arrow-margin($placement) {
  @if ($placement == "top" or $placement == "bottom") {
    margin: 0 7px;
  } @else {
    margin: 4px 0;
  }
}

@mixin roundarrow-margin($placement) {
  @if ($placement == "top" or $placement == "bottom") {
    margin: 0 6px;
  } @else {
    margin: 4px 0;
  }
}

@mixin roundarrow-transform($placement) {
  @if ($placement == "top") {
    transform: rotate(180deg);
  } @else if ($placement == "bottom") {
    transform: rotate(0);
  } @else if ($placement == "left") {
    transform: rotate(90deg);
  } @else if ($placement == "right") {
    transform: rotate(-90deg);
  }
}

@mixin arrow-transform-origin($placement) {
  @if ($placement == "top") {
    transform-origin: 50% 0%;
  } @else if ($placement == "bottom") {
    transform-origin: 50% 100%;
  } @else if ($placement == "left") {
    transform-origin: 0% 50%;
  } @else if ($placement == "right") {
    transform-origin: 100% 50%;
  }
}

@mixin roundarrow-transform-origin($placement) {
  @if ($placement == "top") {
    transform-origin: 50% 0%;
  } @else if ($placement == "bottom") {
    transform-origin: 50% 100%;
  } @else if ($placement == "left") {
    transform-origin: 33.33333333% 50%;
  } @else if ($placement == "right") {
    transform-origin: 66.66666666% 50%;
  }
}
