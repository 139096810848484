// stylelint-disable selector-no-qualifying-type
//
// Chips
//
.btn-chip {
  font-weight: $font-weight-base;
  display: flex;
  align-items: center;
  min-width: 31px;
  min-height: 31px;
  margin: 0 $input-padding-y $input-padding-y 0;
  padding-right: 0.7rem;
  padding-left: 0.7rem;
  white-space: normal;
  border-radius: 5rem;
  background-color: $md-black-lighter;
  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled),
  .disabled & {
    cursor: default;
  }

  .disabled & {
    pointer-events: none;

    .material-icon {
      z-index: -1;
    }
  }

  &.btn-chip-color {
    &:not(:disabled):not(.disabled) {
      @include mat-elevation(1);
    }
  }

  &.text-light {
    .material-icon {
      color: $md-white-darker;

      &:hover {
        color: $white;
      }
    }
  }

  &.text-dark {
    .material-icon {
      color: rgba($black, 0.64);

      &:hover {
        color: $body-color;
      }
    }
  }

  .badge {
    position: static;
    top: auto;
  }

  .badge-tree-count {
    background-color: $md-black-lighter;
  }

  .font-weight-500 {
    font-style: normal;
  }

  &:only-child,
  &:last-child {
    margin-right: $spacer / 2;
  }

  &:not(.btn-chip-color) {
    &::after {
      font-size: 18px;
      margin-right: -4px;
      margin-left: 6px;
      content: $icon_cancel;
      cursor: pointer;
    }

    &:hover {
      &::after {
        color: $body-color;
      }
    }
  }

  .material-icon {
    font-size: 18px;
    display: inline-block;
    margin-right: -4px;
    margin-left: 6px;
    cursor: pointer;
  }

  + .form-group-chip-input {
    .form-control {
      padding-left: 0;
    }
  }
}

.chip-field-name,
.chip-label {
  font-weight: $font-weight-base;
  margin-right: ($spacer / 4);
}

.chip-value {
  font-weight: $btn-font-weight;
  color: $body-color;
}

.btn-chip-hide-close {
  .material-icon {
    z-index: -1;
    opacity: 0;
  }
}

.btn-chip-color-inset {
  box-shadow: inset 0 0 0 1px $md-gray-400;
}

.form-group-chip-list {
  padding: 0 ($input-padding-y / 2);

  .btn-chip {
    margin-top: ($input-padding-y / 2);
    margin-bottom: ($input-padding-y / 2);
  }

  &.disabled {
    opacity: 1;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    background-color: $input-disabled-bg !important;

    .material-icon {
      opacity: 0;
    }

    .btn-chip {
      & + .Expandable {
        display: none;
      }
    }
  }
}

.form-group-chip-input {
  //width: 150px;
  flex: 1 1 25%;

  padding-bottom: 0;

  padding-left: 0;
  &.textarea {
    flex: 1 1 100%;
    padding-left: 0.75rem;
  }
}

.form-control-chip-input {
  max-width: 100%;
  min-height: 37px;
  vertical-align: bottom;
  border: none !important;
  outline: 0;
  background-color: transparent !important;

  &.disabled,
  &:disabled {
    cursor: default;
  }
}

.open-tag {
  margin-right: $input-padding-y;
}
