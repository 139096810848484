//
// Navbar
//
.navbar-primary {
  background-color: $white;
}

.navbar-subnav {
  min-height: 55px;
  border-top: 1px solid $md-gray-300;
  background-color: $md-gray-50;
}

.menubar-hidden {
	.navbar-primary, .navbar-subnav {
		display:none;
	}
}

.menubar-hidden.admin-mode {
	.navbar-primary, .navbar-subnav {
		display:flex;
	}
}

.banner-overall {
  z-index: 10;

  &::before {
    //// Customers and MB can use this to add a build version number that shows when you are not hovering over the banner menu
    //      .banner-overall::before {
    //          content: "DEV/TEST - 8.6 Build 25916";
    //       }
    //// EXAMPLE - DO NOT REMOVE /////
    ////------- content: "DEV/TEST - 8.6 Build 25916"; -----//////
    font-size: 70%;
    font-weight: 700;
    line-height: 1;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 50%;
    padding: 0.25em 0.4em;
    transition: all 300ms ease-in-out;
    transform: translate3d(-50%, 0, 0);
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
    pointer-events: none;
    color: #fff;
    border-radius: 0 0 0.25rem 0.25rem;
    background-color: #f44336;
  }

  &:hover {
    &::before {
      visibility: hidden;
      transform: translate3d(-50%, -100%, 0);
    }
  }
}

.navbar {
  &:empty {
    display: none;
  }

  .ui-scratch-basket-menu-bar-widget {
    .btn-sm {
      @include size(24px);
    }
  }
}

.ext-safari {
  .btn-home {
    background-color: transparent;
  }
}

//.gwt-MenuBarPopup,
//.gwt-MenuBar {
//  table,
//  th,
//  td {
//    border: 0;
//  }
//}
//
//.gwt-MenuBarPopup {
//  table {
//    width: 100%;
//    min-width: 100%;
//  }
//  table,
//  th,
//  td  {
//    padding: 0;
//  }
//}
// Navbar Collapsed and below
// This uses navbar-expand-lg class, in which we use the (md) (below)... so if we change it in one place,
// we have to change it here, vise-versa.
@include media-breakpoint-down(sm) {
  .navbar-primary {
    height: 56px;
    // First, we have to fix the gwt-MenuBar table
    // we will turn this all into block elements
    table {
      &,
      & * {
        display: flex;
        flex-direction: column;
      }
    }

    // Align all button classes to the left
    .btn {
      text-align: left;
    }

    // Make collapse panel position fixed so the page doesn't increase in height when expanded
    .navbar-collapse {
      position: fixed;
      z-index: 20;
      top: 56px;
      right: 0;
      left: 0;
      background-color: $white;
      box-shadow: 0 6px 10px rgba($black, 0.2);
    }
  }
}

.navbar-btn-home {
  position: relative;
  width: 140px;
  margin-right: $spacer;

  .btn-home-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: transform $swift-ease-out-duration $swift-ease-out-timing-function;
    background-image: url("images/logo-MediaBeacon.png");
  }

  &:active {
    .btn-home-bg {
      transform: scale(0.9);
    }
  }

  .material-icon {
    display: none;
  }
}

.NoBottom {
  .menuHolder {
    border: none;
  }

  .menuWidgets {
    display: none;
  }
}

// White links against a dark background
.navbar-dark {
  .badge-primary {
    background-color: $md-black-light;
  }

  .btn-icon-default,
  .material-icon {
    color: $navbar-dark-color;
    @include hover-focus {
      color: $navbar-dark-hover-color;
    }

    &.disabled {
      color: $navbar-dark-disabled-color;
    }
  }
}

.navbar-nav {
  // Quick fix for badges in buttons
  .btn {
    .badge {
      position: relative;
      top: -1px;
    }
  }
}
