//
// Tabs
//
.workspace-tabs-hidden {
  // Only hide the tabs when not in Admin Mode if they are hidden.
  &.no-admin-mode {
    .nav-tabs-workspace {
      > .dragdrop-dropTarget {
        display: none;
      }
    }
  }
}

.nav-tabs {
  .nav-item {
    cursor: pointer;
  }
}

.tabs-material-target {
  .nav-tabs {
    .nav-link {
      position: relative;
      @extend .font-weight-500;
      transition: $default-transition;
      border: none;

      &::after {
        position: absolute;
        right: 1px;
        bottom: 0;
        left: 1px;
        height: 3px;
        content: "";
        background-color: transparent;
      }

      @include hover-focus {
        outline: none;
        &::after {
          background-color: $md-black-lighter;
        }
      }

      &.active {
        color: inherit;
        background-color: transparent;

        &::after {
          background-color: $nav-pills-link-active-bg;
        }
      }
    }
  }
}

.custom-conversion-dialog-tabs {
  position: relative;
}

.FakeGXTTabItem {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  height: 18px;
  margin: 0 0 0 5px;
  padding: 5px 10px;
  @extend .cursor-pointer;
  background-color: $color_celeste_approx;
}

.dragdrop-positioner {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 0.15rem;
  border: none;
  background-color: rgba($black, 0.1);
  @include border-radius($border-radius-lg);
}

.dragdrop-movable-panel {
  .EditableLabel {
    @extend .text-truncate;
  }

  .nav-tabs ~ & {
    border-radius: $border-radius;
    background-color: $md-white-darker;
    @extend .wf-6;
  }
}

.dragdrop-flow-panel-positioner {
  color: $md-blue-a400 !important;
}

.ReorderableTabPanel-tab {
  .hideTabBars & {
    .DraggableTab {
      display: none;
    }
  }
}

.bulkEditTabPanel {
  margin: 4px;
  padding: 4px;
  @extend .border;
}

.ChooseXmpTabPanel {
  z-index: 10000;
}

.DraggableTab {
  .x-tool-gear,
  &.Tabhidden {
    display: none;
  }

  &.DraggableSelected {
    background-color: theme-color("primary");
  }
}
