//
// Card Expansion Panel
//
.card-expansion-panel {
  @include mat-elevation-transition;
  @include mat-overridable-elevation(2);
  overflow: hidden;
  margin: 0;
  transition: margin 225ms $mat-fast-out-slow-in-timing-function;
  border: none;

  .card-header {
    cursor: pointer;
    @include hover-focus {
      outline: none;
    }
  }

  &.is-closed {
    @include border-radius(0);

    .card-header {
      background-color: $md-gray-50;
      // Hover state
      @include hover-focus {
        text-decoration: none;
        color: $list-group-action-hover-color;
        background-color: $list-group-hover-bg;
      }
    }

    .card-expansion-group &:first-child {
      @include border-top-radius($list-group-border-radius);
    }

    .card-expansion-group &:last-child {
      margin-bottom: 0;
      @include border-bottom-radius($list-group-border-radius);

      .card-header {
        border: 0;
      }
    }
  }

  &.is-open {
    margin: $spacer 0;

    .card-expansion-group &:first-child {
      margin-top: 0;
    }

    .card-expansion-group &:last-child {
      margin-bottom: 0;
    }
  }
}
