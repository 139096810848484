//
// Dropdown Menus
//
.dropdown-menu,
.x-date-menu {
  position: absolute;
  display: block;
  border: none;

  &:not(.date-picker-months-years) {
    box-shadow: 0 8px 10px 1px rgba($black, 0.14), 0 3px 14px 2px rgba($black, 0.12), 0 5px 5px -3px rgba($black, 0.2);
  }

  &.x-combo-list {
    height: auto !important;
  }

  &:empty {
    display: none !important;
  }

  .btn-more {
    display: block;
    width: 100%;
    margin-top: $dropdown-padding-y;
    margin-bottom: -$dropdown-padding-y;
    color: $md-black-dark;
    border-width: 1px 0 0 0;
    border-color: $dropdown-divider-bg;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: transparent;
    box-shadow: none;

    &:hover,
    &:focus {
      /* background-color: $dropdown-divider-bg;
	   color: $md-black-darker;*/
      background-color: green !important;
    }
  }

  // Hide paging ex (1 - 10 of 55) in dropdowns
  .my-paging-display {
    display: none;
  }
}

.mb-dropdown-overflow-hidden {
  .tippy-dropdown-menu-theme {
    overflow: hidden;
  }
}

.dropdown-pb-0 {
  .tippy-dropdown-menu-theme {
    padding-bottom: 0;
  }
}

.mb-dropdown-menu-scroll {
  .tippy-dropdown-menu-theme {
    overflow-y: auto;
    height: auto;
    max-height: 90vh;

    .tippy-content {
      width: auto;
      height: auto;
    }
  }
}

.dropdown-item-acl-scroll {
  max-height: 320px !important;
}

.dropdown-menu-max-width {
  .dropdown-header-field-menu & {
    min-width: 280px;
  }
}

.dropdown-header-field-menu {
  .tippy-dropdown-menu-theme {
    // remove bottom gap in dropdown
    padding-bottom: 0 !important;
  }
}

.pinpoint-dropdown {
  font-size: $font-size-sm;
  max-width: 300px;
  text-align: left;
}

.menu-item-bg-holder {
  flex-shrink: 0 !important;
  @include square(22px);
  background-repeat: none;
  background-position: center;
  background-size: cover;
}

.gwt-MenuBarPopup {
  z-index: 501;
}

// QUICK HACK - hide the dropdown divider, then show it when there is a siblining before it.
// This pretty much is needed for context menus where there is only 1 item.
.dropdown-divider {
  display: none;
}

.x-menu-list-item {
  & + .dropdown-divider {
    display: block;
  }
}

.dropdown-menu-combobox {
  min-width: 250px;
}

.dropdown-item {
  position: relative;

  &.dropdown-has-submenu {
    &.tippy-active {
      text-decoration: none;
      color: $dropdown-link-hover-color;
      @include gradient-bg($dropdown-link-hover-bg);
    }
  }
}

.dropdown-footer {
  display: flex;
  align-items: center; // vertically center
  justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
  // Easily place margin between footer elements
  > :not(:first-child) {
    margin-left: 0.25rem;
  }

  > :not(:last-child) {
    margin-right: 0.25rem;
  }
}

.x-menu:not(.dropdown-menu-tippy),
.dropdown-menu {
  ul {
    @extend .list-unstyled !optional;
    margin: 0;
  }
}

.x-menu:not(.dropdown-menu-tippy) {
  @extend .dropdown-menu !optional;
}

.x-combo-list-inner {
  .loading-indicator {
    position: relative;
    display: flex;
    height: 100%;
    padding: $dropdown-item-padding-y * 3 $dropdown-item-padding-x;
    @extend .text-hide !optional;

    &::after {
      margin: auto;
      content: " ";
      @extend .loading-img-icon !optional;
    }
  }

  &.disabled {
    .dropdown-item {
      background-color: transparent !important;

      em {
        font-size: $font-size-base;
        font-style: normal;
        text-align: center;
      }
    }
  }
}

.dropdown-menu-pantone {
  .x-menu-list-item:not(:first-child) {
    border-top: $default-border;
  }

  .dropdown-header {
    padding-bottom: ($spacer * 0.75);
  }

  .pantone-color-container {
    width: ($spacer * 1.5);
  }
}

.dropdown-item {
  em {
    font-size: 80%;
    line-height: $headings-line-height;
    display: block;
    opacity: 0.6;
  }
}

.dropdown-item.nonIndexed,
.dropdown-item-no-index-true {
  opacity: 0.5;

  .material-icon {
    opacity: 0;
  }

  @include hover-focus {
    &,
    .material-icon {
      opacity: 1;
    }
  }
}

.x-combo-selected {
  text-decoration: none;
  color: $dropdown-link-hover-color;
  @include gradient-bg($dropdown-link-hover-bg);
}

.my-paging-text,
.my-paging-display {
  font-size: $font-size-sm;
  display: flex;
  white-space: nowrap;
  opacity: 0.7;
  color: inherit !important;
}

.dropdown-filter-menu {
  &.dropdown-menu {
    min-width: 278px !important;
  }
}

.dropdown-item.loading-icon {
  .material-icon {
    @extend .spin;
  }
}

.dropdown-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  @include transition(all 0.15s ease-in-out);
  text-overflow: ellipsis;

  img {
    flex-shrink: 0 !important;
  }

  &.active-primary {
    &,
    &:active {
      color: $component-active-color;
      background-color: $component-active-bg;
    }

    .material-icon {
      color: $component-active-color;
    }
  }

  &.disabled,
  &:disabled {
    cursor: default;
    color: $dropdown-link-disabled-color;
    background-color: transparent;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }

    &::before,
    &::after,
    .material-icon {
      color: $md-dark-icons-inactive;
    }
  }
}

.dropdown-holder {
  &:not(.dropdown-item) {
    display: block;
    margin-bottom: 0; // for use with heading elements
    padding: $dropdown-padding-y $dropdown-item-padding-x;
    white-space: nowrap; // as with > li > a
  }

  &.dropdown-item {
    &.active,
    &:active {
      color: $dropdown-link-color;
      background-color: transparent;
    }
  }
}

.mb-checkbox {
  & + .dropdown-holder-no-results {
    display: none;
    visibility: hidden;
  }

  &.dropdown-item {
    &::after {
      @include size(36px);
      left: 17px;
      margin-top: -18px;
    }
  }
}

.dropdown-holder-combo-box {
  padding: $dropdown-padding-y $dropdown-item-padding-x;

  &.has-scroll {
    position: relative;

    .input-group {
      z-index: 2;
    }

    &::after {
      position: absolute;
      z-index: 1;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 10px;
      content: "";
      pointer-events: none;
      background-color: $white;
      box-shadow: 0 2px 5px -1px rgba($black, 0.3);
    }

    & + .pretty-scroll {
      padding: ($spacer / 2) 0;
    }
  }
}

.has-scroll-wf {
  position: relative;
}

.btn {
  &.removePMI {
    @include hover-focus {
      background-color: $md-red-50;
      &::before,
      .material-icon {
        color: $md-red-500;
      }
    }
  }
}

.dropdown-item.removePMI {
  @include hover-focus {
    background-color: $md-red-50;
    .material-icon {
      color: $md-red-500;
    }
  }
}

.removePMI {
  .dropdown-item {
    @include hover-focus {
      background-color: $md-red-50;
      &::before,
      .material-icon {
        color: $md-red-500;
      }
    }

    &.disabled,
    &:disabled {
      &::before,
      .material-icon {
        color: $md-dark-icons-inactive;
      }
    }
  }
}

.context-menu {
  transition: height 267ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin-top 267ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 267ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
  border-radius: 2px;
  outline: 1px solid transparent;
}

.x-menu-item-active {
  .Delete-Workspace-cm {
    background-image: $url_mb_71;
  }
}

.x-menu-item-arrow {
  position: relative;
  padding-right: $dropdown-item-padding-x * 2;
  background: transparent;

  &::after {
    position: absolute;
    @include absolute-centered-y($default-icon-font-size);
    right: $dropdown-item-padding-x;
    margin-right: -($dropdown-item-padding-x / 2);
    content: "chevron_right";
  }
}

.x-menu-item-icon {
  position: relative;
  display: inline-block;
  @include size(22px);
  margin-right: $spacer;
  background-position: center;
}

.dropdown-item {
  .material-svg-icon {
    display: inline-block;
    vertical-align: bottom;
  }

  &::before {
    @include absolute-centered-y($default-icon-font-size);
  }

  &::before {
    @extend .material-icon;
    @include absolute-centered-y($default-icon-font-size);
  }
}

.dropdown-header-plus {
  width: 100%;
  max-width: 400px;
}

.HeaderPlusMenu {
  .x-grid3,
  .x-grid3-scroller,
  .x-grid3-row,
  .x-grid3-row-table {
    width: 100% !important;
  }

  .x-grid3-td-namemapping {
    display: flex;
    width: auto !important;
  }
}
