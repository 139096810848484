//
// Workflow M3ta Form Widget
//
.card-m3taform-widget {
  &:not(.minimized) {
    .collapse {
      display: block;
      height: auto !important;
    }
    .xmp-tab-asset-container {
      &.collapse:not(.show) {
        display: none !important;
      }
    }
  }
}

.list-group-advanced-xmp {
  .aside .M3TAFormWidget & {
    .FieldValueLabel {
      @extend .text-secondary;
    }

    .no-gutters {
      .px-3 {
        @extend .col-12;
      }
    }

    .row:last-child {
      padding-bottom: ($spacer / 2) !important;
    }
  }
}

//.M3TAFormWidget-root {
//  .x-panel-body-noheader {
//    border: none;
//  }
//  .EditFieldContentPanel {
//    margin: 3px;
//  }
//  .FieldEditPanel {
//    @extend .w-100;
//  }
//}
//
//.M3TAFormWidget .MBItemizedTextbox .TextBoxItem {
//  white-space: normal;
//}
//
//.m3taform_placeholder {
//  .HideFieldEmpty & {
//    .waiting {
//      margin-bottom: 0 !important;
//    }
//  }
//}
//
//.RequiredUploadM3taformPanel {
//  .UploadProgressDialogInternal & {
//    margin-top: 4px;
//  }
//}
//
//.M3TAForms-List-cm {
//  background-image: $url_mb_40;
//}
//.M3taForms-link-cm {
//  background-image: $url_mb_41;
//}
//
//.InformationCollectingM3TAForm {
//  height: auto;
//}
//
//.Upload-With-M3TAForms-cm {
//  background-image: $url_mb_10;
//}
//
//.RequiredUploadM3taformPanel .FieldEditPanel {
//  position: inherit;
//}
//
//.WorkflowM3TAFormConfigPanel .CodeEditor {
//  width: 500px;
//  height: 400px;
//}
//
//.WorkflowM3TAFormWidget-root .FieldEditPanel {
//  @extend .w-100;
//}
