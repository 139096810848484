//
// White Frames / Elevation (Box Shadows)
//
$index: 0;
@for $index from 0 through 24 {
  .wf-#{$index} {
    @include mat-elevation($index);
  }
  $index: $index + 1;
}

.wf-scroll-container {
  z-index: 2;
  box-shadow: 0 3px 3px -2px rgba($black, 0.2), 0 3px 4px 0 rgba($black, 0.14);
}

.wf-transition-active {
  @include mat-elevation(1);
  @include mat-elevation-transition();
  @include hover-focus {
    @include mat-elevation(3);
  }
  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    @include box-shadow(none);
  }

  &.active,
  &.is-open {
    @include mat-elevation(6);
  }
}

.wf-transition-hover {
  @include mat-elevation-transition();
  @include hover-focus {
    @include mat-elevation(8);
  }
  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    @include box-shadow(none);
  }
}
