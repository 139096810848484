//
// Video Editor Widget
//
.card-video-editor-view-widget {
  max-height: 85vh;

  &.active-view-widget {
    &:not(.minimized) {
      flex: 1 1 auto !important;
    }
  }

  .x-grid3-scroller {
    display: none;
  }

  &.DropZone-dragging {
    box-shadow: none;

    .VideoEditTimeline {
      @include drag-drop-highlight();
    }
  }

  .tile-view-image-grid:hover {
    .meta-holder-hover {
      opacity: 1;
    }
  }

  .user-avatar {
    display: none;
  }

  .VideoEditTimeline-internal {
    width: 100%;
  }

  .VideoEditTimeline {
    &.timeline-empty {
      .VideoClipHolder.dragdrop-dropTarget {
        height: 100px;
      }

      .timeline-empty-label {
        display: inherit !important;
      }
    }

    .timeline-empty-label {
      line-height: 125px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: none;
      text-align: center;
      vertical-align: middle;
      color: $white;
    }
  }

  .video-clip-holder {
    height: auto !important;
  }

  //
  //.VideoEditTimelinePreviewPanel {
  //  background-color: $md-gray-800;
  //  @include border-radius($border-radius-lg);
  //}
}
