//
// Tippy JS Imports
// NOTE: Tippy.js automatically injects Tippy's CSS stylesheet into the document head.
// This is just for themes
//
//@import "~tippy.js/dist/tippy";
@import "~tippy.js/dist/tippy";
@import "~tippy.js/dist/themes/light";
@import "~tippy.js/dist/themes/google";
@import "tippy/themes/light";

.tippy-dropdown-menu-theme {
	font-size: $font-size-base !important; // Redeclare because nesting can cause inheritance issues
	display: block;
	min-width: $dropdown-min-width;
	margin: $dropdown-spacer 0 0; // override default ul
	padding: $dropdown-padding-y 0 !important;
	list-style: none;
	text-align: left;
	color: $body-color;
	background-color: $dropdown-bg;
	background-clip: padding-box;
}

.tippy-dropdown-menu-theme,
.dropdown-menu-theme,
.tippy-tooltip.light-theme {
	//box-shadow: 0 8px 10px 1px rgba($black, .14), 0 3px 14px 2px rgba($black, 0.12), 0 5px 5px -3px rgba($black, 0.2);
	@include mat-elevation(15);
}

.ui-header-plus-menu {
	z-index: $zindex-modal-backdrop - 1 !important;
}
