//
// Icons
//
$icon_3d_rotation: "3d_rotation";
$icon_ac_unit: "ac_unit";
$icon_access_alarm: "access_alarm";
$icon_access_alarms: "access_alarms";
$icon_access_time: "access_time";
$icon_accessibility: "accessibility";
$icon_accessible: "accessible";
$icon_account_balance: "account_balance";
$icon_account_balance_wallet: "account_balance_wallet";
$icon_account_box: "account_box";
$icon_account_circle: "account_circle";
$icon_adb: "adb";
$icon_add: "add";
$icon_add_a_photo: "add_a_photo";
$icon_add_alarm: "add_alarm";
$icon_add_alert: "add_alert";
$icon_add_box: "add_box";
$icon_add_circle: "add_circle";
$icon_add_circle_outline: "add_circle_outline";
$icon_add_location: "add_location";
$icon_add_shopping_cart: "add_shopping_cart";
$icon_font_size: 22px;
$icon_add_to_photos: "add_to_photos";
$icon_add_to_queue: "add_to_queue";
$icon_adjust: "adjust";
$icon_airline_seat_flat: "airline_seat_flat";
$icon_airline_seat_flat_angled: "airline_seat_flat_angled";
$icon_airline_seat_individual_suite: "airline_seat_individual_suite";
$icon_airline_seat_legroom_extra: "airline_seat_legroom_extra";
$icon_airline_seat_legroom_normal: "airline_seat_legroom_normal";
$icon_airline_seat_legroom_reduced: "airline_seat_legroom_reduced";
$icon_airline_seat_recline_extra: "airline_seat_recline_extra";
$icon_airline_seat_recline_normal: "airline_seat_recline_normal";
$icon_airplanemode_active: "airplanemode_active";
$icon_airplanemode_inactive: "airplanemode_inactive";
$icon_airplay: "airplay";
$icon_airport_shuttle: "airport_shuttle";
$icon_alarm: "alarm";
$icon_alarm_add: "alarm_add";
$icon_alarm_off: "alarm_off";
$icon_alarm_on: "alarm_on";
$icon_album: "album";
$icon_all_inclusive: "all_inclusive";
$icon_all_out: "all_out";
$icon_android: "android";
$icon_announcement: "announcement";
$icon_apps: "apps";
$icon_archive: "archive";
$icon_arrow_back: "arrow_back";
$icon_arrow_downward: "arrow_downward";
$icon_arrow_drop_down: "arrow_drop_down";
$icon_arrow_drop_down_circle: "arrow_drop_down_circle";
$icon_arrow_drop_up: "arrow_drop_up";
$icon_arrow_forward: "arrow_forward";
$icon_arrow_upward: "arrow_upward";
$icon_art_track: "art_track";
$icon_aspect_ratio: "aspect_ratio";
$icon_assessment: "assessment";
$icon_assignment: "assignment";
$icon_assignment_ind: "assignment_ind";
$icon_assignment_late: "assignment_late";
$icon_assignment_return: "assignment_return";
$icon_assignment_returned: "assignment_returned";
$icon_assignment_turned_in: "assignment_turned_in";
$icon_assistant: "assistant";
$icon_assistant_photo: "assistant_photo";
$icon_attach_file: "attach_file";
$icon_attach_money: "attach_money";
$icon_attachment: "attachment";
$icon_audiotrack: "audiotrack";
$icon_autorenew: "autorenew";
$icon_av_timer: "av_timer";
$icon_backspace: "backspace";
$icon_backup: "backup";
$icon_battery_alert: "battery_alert";
$icon_battery_charging_full: "battery_charging_full";
$icon_battery_full: "battery_full";
$icon_battery_std: "battery_std";
$icon_battery_unknown: "battery_unknown";
$icon_beach_access: "beach_access";
$icon_beenhere: "beenhere";
$icon_block: "block";
$icon_bluetooth: "bluetooth";
$icon_bluetooth_audio: "bluetooth_audio";
$icon_bluetooth_connected: "bluetooth_connected";
$icon_bluetooth_disabled: "bluetooth_disabled";
$icon_bluetooth_searching: "bluetooth_searching";
$icon_blur_circular: "blur_circular";
$icon_blur_linear: "blur_linear";
$icon_blur_off: "blur_off";
$icon_blur_on: "blur_on";
$icon_book: "book";
$icon_bookmark: "bookmark";
$icon_bookmark_border: "bookmark_border";
$icon_border_all: "border_all";
$icon_border_bottom: "border_bottom";
$icon_border_clear: "border_clear";
$icon_border_color: "border_color";
$icon_border_horizontal: "border_horizontal";
$icon_border_inner: "border_inner";
$icon_border_left: "border_left";
$icon_border_outer: "border_outer";
$icon_border_right: "border_right";
$icon_border_style: "border_style";
$icon_border_top: "border_top";
$icon_border_vertical: "border_vertical";
$icon_branding_watermark: "branding_watermark";
$icon_brightness_1: "brightness_1";
$icon_brightness_2: "brightness_2";
$icon_brightness_3: "brightness_3";
$icon_brightness_4: "brightness_4";
$icon_brightness_5: "brightness_5";
$icon_brightness_6: "brightness_6";
$icon_brightness_7: "brightness_7";
$icon_brightness_auto: "brightness_auto";
$icon_brightness_high: "brightness_high";
$icon_brightness_low: "brightness_low";
$icon_brightness_medium: "brightness_medium";
$icon_broken_image: "broken_image";
$icon_brush: "brush";
$icon_bubble_chart: "bubble_chart";
$icon_bug_report: "bug_report";
$icon_build: "build";
$icon_burst_mode: "burst_mode";
$icon_business: "business";
$icon_business_center: "business_center";
$icon_cached: "cached";
$icon_cake: "cake";
$icon_call: "call";
$icon_call_end: "call_end";
$icon_call_made: "call_made";
$icon_call_merge: "call_merge";
$icon_call_missed: "call_missed";
$icon_call_missed_outgoing: "call_missed_outgoing";
$icon_call_received: "call_received";
$icon_call_split: "call_split";
$icon_call_to_action: "call_to_action";
$icon_camera: "camera";
$icon_camera_alt: "camera_alt";
$icon_camera_enhance: "camera_enhance";
$icon_camera_front: "camera_front";
$icon_camera_rear: "camera_rear";
$icon_camera_roll: "camera_roll";
$icon_cancel: "cancel";
$icon_card_giftcard: "card_giftcard";
$icon_card_membership: "card_membership";
$icon_card_travel: "card_travel";
$icon_casino: "casino";
$icon_cast: "cast";
$icon_cast_connected: "cast_connected";
$icon_center_focus_strong: "center_focus_strong";
$icon_center_focus_weak: "center_focus_weak";
$icon_change_history: "change_history";
$icon_chat: "chat";
$icon_chat_bubble: "chat_bubble";
$icon_chat_bubble_outline: "chat_bubble_outline";
$icon_check: "check";
$icon_check_box: "check_box";
$icon_check_box_outline_blank: "check_box_outline_blank";
$icon_check_circle: "check_circle";
$icon_chevron_left: "chevron_left";
$icon_chevron_right: "chevron_right";
$icon_child_care: "child_care";
$icon_child_friendly: "child_friendly";
$icon_chrome_reader_mode: "chrome_reader_mode";
$icon_class: "class";
$icon_clear: "clear";
$icon_clear_all: "clear_all";
$icon_close: "close";
$icon_closed_caption: "closed_caption";
$icon_cloud: "cloud";
$icon_cloud_circle: "cloud_circle";
$icon_cloud_done: "cloud_done";
$icon_cloud_download: "cloud_download";
$icon_cloud_off: "cloud_off";
$icon_cloud_queue: "cloud_queue";
$icon_cloud_upload: "cloud_upload";
$icon_code: "code";
$icon_collections: "collections";
$icon_collections_bookmark: "collections_bookmark";
$icon_color_lens: "color_lens";
$icon_colorize: "colorize";
$icon_comment: "comment";
$icon_compare: "compare";
$icon_compare_arrows: "compare_arrows";
$icon_computer: "computer";
$icon_confirmation_number: "confirmation_number";
$icon_contact_mail: "contact_mail";
$icon_contact_phone: "contact_phone";
$icon_contacts: "contacts";
$icon_content_copy: "content_copy";
$icon_content_cut: "content_cut";
$icon_content_paste: "content_paste";
$icon_control_point: "control_point";
$icon_control_point_duplicate: "control_point_duplicate";
$icon_copyright: "copyright";
$icon_create: "create";
$icon_create_new_folder: "create_new_folder";
$icon_credit_card: "credit_card";
$icon_crop: "crop";
$icon_crop_16_9: "crop_16_9";
$icon_crop_3_2: "crop_3_2";
$icon_crop_5_4: "crop_5_4";
$icon_crop_7_5: "crop_7_5";
$icon_crop_din: "crop_din";
$icon_crop_free: "crop_free";
$icon_crop_landscape: "crop_landscape";
$icon_crop_original: "crop_original";
$icon_crop_portrait: "crop_portrait";
$icon_crop_rotate: "crop_rotate";
$icon_crop_square: "crop_square";
$icon_dashboard: "dashboard";
$icon_data_usage: "data_usage";
$icon_date_range: "date_range";
$icon_dehaze: "dehaze";
$icon_delete: "delete";
$icon_delete_forever: "delete_forever";
$icon_delete_sweep: "delete_sweep";
$icon_description: "description";
$icon_desktop_mac: "desktop_mac";
$icon_desktop_windows: "desktop_windows";
$icon_details: "details";
$icon_developer_board: "developer_board";
$icon_developer_mode: "developer_mode";
$icon_device_hub: "device_hub";
$icon_devices: "devices";
$icon_devices_other: "devices_other";
$icon_dialer_sip: "dialer_sip";
$icon_dialpad: "dialpad";
$icon_directions: "directions";
$icon_directions_bike: "directions_bike";
$icon_directions_boat: "directions_boat";
$icon_directions_bus: "directions_bus";
$icon_directions_car: "directions_car";
$icon_directions_railway: "directions_railway";
$icon_directions_run: "directions_run";
$icon_directions_subway: "directions_subway";
$icon_directions_transit: "directions_transit";
$icon_directions_walk: "directions_walk";
$icon_disc_full: "disc_full";
$icon_dns: "dns";
$icon_do_not_disturb: "do_not_disturb";
$icon_do_not_disturb_alt: "do_not_disturb_alt";
$icon_do_not_disturb_off: "do_not_disturb_off";
$icon_do_not_disturb_on: "do_not_disturb_on";
$icon_dock: "dock";
$icon_domain: "domain";
$icon_done: "done";
$icon_done_all: "done_all";
$icon_donut_large: "donut_large";
$icon_donut_small: "donut_small";
$icon_drafts: "drafts";
$icon_drag_handle: "drag_handle";
$icon_drive_eta: "drive_eta";
$icon_dvr: "dvr";
$icon_edit: "edit";
$icon_edit_location: "edit_location";
$icon_eject: "eject";
$icon_email: "email";
$icon_enhanced_encryption: "enhanced_encryption";
$icon_equalizer: "equalizer";
$icon_error: "error";
$icon_error_outline: "error_outline";
$icon_euro_symbol: "euro_symbol";
$icon_ev_station: "ev_station";
$icon_event: "event";
$icon_event_available: "event_available";
$icon_event_busy: "event_busy";
$icon_event_note: "event_note";
$icon_event_seat: "event_seat";
$icon_exit_to_app: "exit_to_app";
$icon_expand_less: "expand_less";
$icon_expand_more: "expand_more";
$icon_explicit: "explicit";
$icon_explore: "explore";
$icon_exposure: "exposure";
$icon_exposure_neg_1: "exposure_neg_1";
$icon_exposure_neg_2: "exposure_neg_2";
$icon_exposure_plus_1: "exposure_plus_1";
$icon_exposure_plus_2: "exposure_plus_2";
$icon_exposure_zero: "exposure_zero";
$icon_extension: "extension";
$icon_face: "face";
$icon_fast_forward: "fast_forward";
$icon_fast_rewind: "fast_rewind";
$icon_favorite: "favorite";
$icon_favorite_border: "favorite_border";
$icon_featured_play_list: "featured_play_list";
$icon_featured_video: "featured_video";
$icon_feedback: "feedback";
$icon_fiber_dvr: "fiber_dvr";
$icon_fiber_manual_record: "fiber_manual_record";
$icon_fiber_new: "fiber_new";
$icon_fiber_pin: "fiber_pin";
$icon_fiber_smart_record: "fiber_smart_record";
$icon_file_download: "file_download";
$icon_file_upload: "file_upload";
$icon_filter: "filter";
$icon_filter_1: "filter_1";
$icon_filter_2: "filter_2";
$icon_filter_3: "filter_3";
$icon_filter_4: "filter_4";
$icon_filter_5: "filter_5";
$icon_filter_6: "filter_6";
$icon_filter_7: "filter_7";
$icon_filter_8: "filter_8";
$icon_filter_9: "filter_9";
$icon_filter_9_plus: "filter_9_plus";
$icon_filter_b_and_w: "filter_b_and_w";
$icon_filter_center_focus: "filter_center_focus";
$icon_filter_drama: "filter_drama";
$icon_filter_frames: "filter_frames";
$icon_filter_hdr: "filter_hdr";
$icon_filter_list: "filter_list";
$icon_filter_none: "filter_none";
$icon_filter_tilt_shift: "filter_tilt_shift";
$icon_filter_vintage: "filter_vintage";
$icon_find_in_page: "find_in_page";
$icon_find_replace: "find_replace";
$icon_fingerprint: "fingerprint";
$icon_first_page: "first_page";
$icon_fitness_center: "fitness_center";
$icon_flag: "flag";
$icon_flare: "flare";
$icon_flash_auto: "flash_auto";
$icon_flash_off: "flash_off";
$icon_flash_on: "flash_on";
$icon_flight: "flight";
$icon_flight_land: "flight_land";
$icon_flight_takeoff: "flight_takeoff";
$icon_flip: "flip";
$icon_flip_to_back: "flip_to_back";
$icon_flip_to_front: "flip_to_front";
$icon_folder: "folder";
$icon_folder_open: "folder_open";
$icon_folder_shared: "folder_shared";
$icon_folder_special: "folder_special";
$icon_font_download: "font_download";
$icon_format_align_center: "format_align_center";
$icon_format_align_justify: "format_align_justify";
$icon_format_align_left: "format_align_left";
$icon_format_align_right: "format_align_right";
$icon_format_bold: "format_bold";
$icon_format_clear: "format_clear";
$icon_format_color_fill: "format_color_fill";
$icon_format_color_reset: "format_color_reset";
$icon_format_color_text: "format_color_text";
$icon_format_indent_decrease: "format_indent_decrease";
$icon_format_indent_increase: "format_indent_increase";
$icon_format_italic: "format_italic";
$icon_format_line_spacing: "format_line_spacing";
$icon_format_list_bulleted: "format_list_bulleted";
$icon_format_list_numbered: "format_list_numbered";
$icon_format_paint: "format_paint";
$icon_format_quote: "format_quote";
$icon_format_shapes: "format_shapes";
$icon_format_size: "format_size";
$icon_format_strikethrough: "format_strikethrough";
$icon_format_textdirection_l_to_r: "format_textdirection_l_to_r";
$icon_format_textdirection_r_to_l: "format_textdirection_r_to_l";
$icon_format_underlined: "format_underlined";
$icon_forum: "forum";
$icon_forward: "forward";
$icon_forward_10: "forward_10";
$icon_forward_30: "forward_30";
$icon_forward_5: "forward_5";
$icon_free_breakfast: "free_breakfast";
$icon_fullscreen: "fullscreen";
$icon_fullscreen_exit: "fullscreen_exit";
$icon_functions: "functions";
$icon_g_translate: "g_translate";
$icon_gamepad: "gamepad";
$icon_games: "games";
$icon_gavel: "gavel";
$icon_gesture: "gesture";
$icon_get_app: "get_app";
$icon_gif: "gif";
$icon_golf_course: "golf_course";
$icon_gps_fixed: "gps_fixed";
$icon_gps_not_fixed: "gps_not_fixed";
$icon_gps_off: "gps_off";
$icon_grade: "grade";
$icon_gradient: "gradient";
$icon_grain: "grain";
$icon_graphic_eq: "graphic_eq";
$icon_grid_off: "grid_off";
$icon_grid_on: "grid_on";
$icon_group: "group";
$icon_group_add: "group_add";
$icon_group_work: "group_work";
$icon_hd: "hd";
$icon_hdr_off: "hdr_off";
$icon_hdr_on: "hdr_on";
$icon_hdr_strong: "hdr_strong";
$icon_hdr_weak: "hdr_weak";
$icon_headset: "headset";
$icon_headset_mic: "headset_mic";
$icon_healing: "healing";
$icon_hearing: "hearing";
$icon_help: "help";
$icon_help_outline: "help_outline";
$icon_high_quality: "high_quality";
$icon_highlight: "highlight";
$icon_highlight_off: "highlight_off";
$icon_history: "history";
$icon_home: "home";
$icon_hot_tub: "hot_tub";
$icon_hotel: "hotel";
$icon_hourglass_empty: "hourglass_empty";
$icon_hourglass_full: "hourglass_full";
$icon_http: "http";
$icon_https: "https";
$icon_image: "image";
$icon_image_aspect_ratio: "image_aspect_ratio";
$icon_import_contacts: "import_contacts";
$icon_import_export: "import_export";
$icon_important_devices: "important_devices";
$icon_inbox: "inbox";
$icon_indeterminate_check_box: "indeterminate_check_box";
$icon_info: "info";
$icon_info_outline: "info_outline";
$icon_input: "input";
$icon_insert_chart: "insert_chart";
$icon_insert_comment: "insert_comment";
$icon_insert_drive_file: "insert_drive_file";
$icon_insert_emoticon: "insert_emoticon";
$icon_insert_invitation: "insert_invitation";
$icon_insert_link: "insert_link";
$icon_insert_photo: "insert_photo";
$icon_invert_colors: "invert_colors";
$icon_invert_colors_off: "invert_colors_off";
$icon_iso: "iso";
$icon_keyboard: "keyboard";
$icon_keyboard_arrow_down: "keyboard_arrow_down";
$icon_keyboard_arrow_left: "keyboard_arrow_left";
$icon_keyboard_arrow_right: "keyboard_arrow_right";
$icon_keyboard_arrow_up: "keyboard_arrow_up";
$icon_keyboard_backspace: "keyboard_backspace";
$icon_keyboard_capslock: "keyboard_capslock";
$icon_keyboard_hide: "keyboard_hide";
$icon_keyboard_return: "keyboard_return";
$icon_keyboard_tab: "keyboard_tab";
$icon_keyboard_voice: "keyboard_voice";
$icon_kitchen: "kitchen";
$icon_label: "label";
$icon_label_outline: "label_outline";
$icon_landscape: "landscape";
$icon_language: "language";
$icon_laptop: "laptop";
$icon_laptop_chromebook: "laptop_chromebook";
$icon_laptop_mac: "laptop_mac";
$icon_laptop_windows: "laptop_windows";
$icon_last_page: "last_page";
$icon_launch: "launch";
$icon_layers: "layers";
$icon_layers_clear: "layers_clear";
$icon_leak_add: "leak_add";
$icon_leak_remove: "leak_remove";
$icon_lens: "lens";
$icon_library_add: "library_add";
$icon_library_books: "library_books";
$icon_library_music: "library_music";
$icon_lightbulb_outline: "lightbulb_outline";
$icon_line_style: "line_style";
$icon_line_weight: "line_weight";
$icon_linear_scale: "linear_scale";
$icon_link: "link";
$icon_linked_camera: "linked_camera";
$icon_list: "list";
$icon_live_help: "live_help";
$icon_live_tv: "live_tv";
$icon_local_activity: "local_activity";
$icon_local_airport: "local_airport";
$icon_local_atm: "local_atm";
$icon_local_bar: "local_bar";
$icon_local_cafe: "local_cafe";
$icon_local_car_wash: "local_car_wash";
$icon_local_convenience_store: "local_convenience_store";
$icon_local_dining: "local_dining";
$icon_local_drink: "local_drink";
$icon_local_florist: "local_florist";
$icon_local_gas_station: "local_gas_station";
$icon_local_grocery_store: "local_grocery_store";
$icon_local_hospital: "local_hospital";
$icon_local_hotel: "local_hotel";
$icon_local_laundry_service: "local_laundry_service";
$icon_local_library: "local_library";
$icon_local_mall: "local_mall";
$icon_local_movies: "local_movies";
$icon_local_offer: "local_offer";
$icon_local_parking: "local_parking";
$icon_local_pharmacy: "local_pharmacy";
$icon_local_phone: "local_phone";
$icon_local_pizza: "local_pizza";
$icon_local_play: "local_play";
$icon_local_post_office: "local_post_office";
$icon_local_printshop: "local_printshop";
$icon_local_see: "local_see";
$icon_local_shipping: "local_shipping";
$icon_local_taxi: "local_taxi";
$icon_location_city: "location_city";
$icon_location_disabled: "location_disabled";
$icon_location_off: "location_off";
$icon_location_on: "location_on";
$icon_location_searching: "location_searching";
$icon_lock: "lock";
$icon_lock_open: "lock_open";
$icon_lock_outline: "lock_outline";
$icon_looks: "looks";
$icon_looks_3: "looks_3";
$icon_looks_4: "looks_4";
$icon_looks_5: "looks_5";
$icon_looks_6: "looks_6";
$icon_looks_one: "looks_one";
$icon_looks_two: "looks_two";
$icon_loop: "loop";
$icon_loupe: "loupe";
$icon_low_priority: "low_priority";
$icon_loyalty: "loyalty";
$icon_mail: "mail";
$icon_mail_outline: "mail_outline";
$icon_map: "map";
$icon_markunread: "markunread";
$icon_markunread_mailbox: "markunread_mailbox";
$icon_memory: "memory";
$icon_menu: "menu";
$icon_merge_type: "merge_type";
$icon_message: "message";
$icon_mic: "mic";
$icon_mic_none: "mic_none";
$icon_mic_off: "mic_off";
$icon_mms: "mms";
$icon_mode_comment: "mode_comment";
$icon_mode_edit: "mode_edit";
$icon_monetization_on: "monetization_on";
$icon_money_off: "money_off";
$icon_monochrome_photos: "monochrome_photos";
$icon_mood: "mood";
$icon_mood_bad: "mood_bad";
$icon_more: "more";
$icon_more_horiz: "more_horiz";
$icon_more_vert: "more_vert";
$icon_motorcycle: "motorcycle";
$icon_mouse: "mouse";
$icon_move_to_inbox: "move_to_inbox";
$icon_movie: "movie";
$icon_movie_creation: "movie_creation";
$icon_movie_filter: "movie_filter";
$icon_multiline_chart: "multiline_chart";
$icon_music_note: "music_note";
$icon_music_video: "music_video";
$icon_my_location: "my_location";
$icon_nature: "nature";
$icon_nature_people: "nature_people";
$icon_navigate_before: "navigate_before";
$icon_navigate_next: "navigate_next";
$icon_navigation: "navigation";
$icon_near_me: "near_me";
$icon_network_cell: "network_cell";
$icon_network_check: "network_check";
$icon_network_locked: "network_locked";
$icon_network_wifi: "network_wifi";
$icon_new_releases: "new_releases";
$icon_next_week: "next_week";
$icon_nfc: "nfc";
$icon_no_encryption: "no_encryption";
$icon_no_sim: "no_sim";
$icon_not_interested: "not_interested";
$icon_note: "note";
$icon_note_add: "note_add";
$icon_notifications: "notifications";
$icon_notifications_active: "notifications_active";
$icon_notifications_none: "notifications_none";
$icon_notifications_off: "notifications_off";
$icon_notifications_paused: "notifications_paused";
$icon_offline_pin: "offline_pin";
$icon_ondemand_video: "ondemand_video";
$icon_opacity: "opacity";
$icon_open_in_browser: "open_in_browser";
$icon_open_in_new: "open_in_new";
$icon_open_with: "open_with";
$icon_pages: "pages";
$icon_pageview: "pageview";
$icon_palette: "palette";
$icon_pan_tool: "pan_tool";
$icon_panorama: "panorama";
$icon_panorama_fish_eye: "panorama_fish_eye";
$icon_panorama_horizontal: "panorama_horizontal";
$icon_panorama_vertical: "panorama_vertical";
$icon_panorama_wide_angle: "panorama_wide_angle";
$icon_party_mode: "party_mode";
$icon_pause: "pause";
$icon_pause_circle_filled: "pause_circle_filled";
$icon_pause_circle_outline: "pause_circle_outline";
$icon_payment: "payment";
$icon_people: "people";
$icon_people_outline: "people_outline";
$icon_perm_camera_mic: "perm_camera_mic";
$icon_perm_contact_calendar: "perm_contact_calendar";
$icon_perm_data_setting: "perm_data_setting";
$icon_perm_device_information: "perm_device_information";
$icon_perm_identity: "perm_identity";
$icon_perm_media: "perm_media";
$icon_perm_phone_msg: "perm_phone_msg";
$icon_perm_scan_wifi: "perm_scan_wifi";
$icon_person: "person";
$icon_person_add: "person_add";
$icon_person_outline: "person_outline";
$icon_person_pin: "person_pin";
$icon_person_pin_circle: "person_pin_circle";
$icon_personal_video: "personal_video";
$icon_pets: "pets";
$icon_phone: "phone";
$icon_phone_android: "phone_android";
$icon_phone_bluetooth_speaker: "phone_bluetooth_speaker";
$icon_phone_forwarded: "phone_forwarded";
$icon_phone_in_talk: "phone_in_talk";
$icon_phone_iphone: "phone_iphone";
$icon_phone_locked: "phone_locked";
$icon_phone_missed: "phone_missed";
$icon_phone_paused: "phone_paused";
$icon_phonelink: "phonelink";
$icon_phonelink_erase: "phonelink_erase";
$icon_phonelink_lock: "phonelink_lock";
$icon_phonelink_off: "phonelink_off";
$icon_phonelink_ring: "phonelink_ring";
$icon_phonelink_setup: "phonelink_setup";
$icon_photo: "photo";
$icon_photo_album: "photo_album";
$icon_photo_camera: "photo_camera";
$icon_photo_filter: "photo_filter";
$icon_photo_library: "photo_library";
$icon_photo_size_select_actual: "photo_size_select_actual";
$icon_photo_size_select_large: "photo_size_select_large";
$icon_photo_size_select_small: "photo_size_select_small";
$icon_picture_as_pdf: "picture_as_pdf";
$icon_picture_in_picture: "picture_in_picture";
$icon_picture_in_picture_alt: "picture_in_picture_alt";
$icon_pie_chart: "pie_chart";
$icon_pie_chart_outlined: "pie_chart_outlined";
$icon_pin_drop: "pin_drop";
$icon_place: "place";
$icon_play_arrow: "play_arrow";
$icon_play_circle_filled: "play_circle_filled";
$icon_play_circle_outline: "play_circle_outline";
$icon_play_for_work: "play_for_work";
$icon_playlist_add: "playlist_add";
$icon_playlist_add_check: "playlist_add_check";
$icon_playlist_play: "playlist_play";
$icon_plus_one: "plus_one";
$icon_poll: "poll";
$icon_polymer: "polymer";
$icon_pool: "pool";
$icon_portable_wifi_off: "portable_wifi_off";
$icon_portrait: "portrait";
$icon_power: "power";
$icon_power_input: "power_input";
$icon_power_settings_new: "power_settings_new";
$icon_pregnant_woman: "pregnant_woman";
$icon_present_to_all: "present_to_all";
$icon_print: "print";
$icon_priority_high: "priority_high";
$icon_public: "public";
$icon_publish: "publish";
$icon_query_builder: "query_builder";
$icon_question_answer: "question_answer";
$icon_queue: "queue";
$icon_queue_music: "queue_music";
$icon_queue_play_next: "queue_play_next";
$icon_radio: "radio";
$icon_radio_button_checked: "radio_button_checked";
$icon_radio_button_unchecked: "radio_button_unchecked";
$icon_rate_review: "rate_review";
$icon_receipt: "receipt";
$icon_recent_actors: "recent_actors";
$icon_record_voice_over: "record_voice_over";
$icon_redeem: "redeem";
$icon_redo: "redo";
$icon_refresh: "refresh";
$icon_remove: "remove";
$icon_remove_circle: "remove_circle";
$icon_remove_circle_outline: "remove_circle_outline";
$icon_remove_from_queue: "remove_from_queue";
$icon_remove_red_eye: "remove_red_eye";
$icon_remove_shopping_cart: "remove_shopping_cart";
$icon_reorder: "reorder";
$icon_repeat: "repeat";
$icon_repeat_one: "repeat_one";
$icon_replay: "replay";
$icon_replay_10: "replay_10";
$icon_replay_30: "replay_30";
$icon_replay_5: "replay_5";
$icon_reply: "reply";
$icon_reply_all: "reply_all";
$icon_report: "report";
$icon_report_problem: "report_problem";
$icon_restaurant: "restaurant";
$icon_restaurant_menu: "restaurant_menu";
$icon_restore: "restore";
$icon_restore_page: "restore_page";
$icon_ring_volume: "ring_volume";
$icon_room: "room";
$icon_room_service: "room_service";
$icon_rotate_90_degrees_ccw: "rotate_90_degrees_ccw";
$icon_rotate_left: "rotate_left";
$icon_rotate_right: "rotate_right";
$icon_rounded_corner: "rounded_corner";
$icon_router: "router";
$icon_rowing: "rowing";
$icon_rss_feed: "rss_feed";
$icon_rv_hookup: "rv_hookup";
$icon_satellite: "satellite";
$icon_save: "save";
$icon_scanner: "scanner";
$icon_schedule: "schedule";
$icon_school: "school";
$icon_screen_lock_landscape: "screen_lock_landscape";
$icon_screen_lock_portrait: "screen_lock_portrait";
$icon_screen_lock_rotation: "screen_lock_rotation";
$icon_screen_rotation: "screen_rotation";
$icon_screen_share: "screen_share";
$icon_sd_card: "sd_card";
$icon_sd_storage: "sd_storage";
$icon_search: "search";
$icon_security: "security";
$icon_select_all: "select_all";
$icon_send: "send";
$icon_sentiment_dissatisfied: "sentiment_dissatisfied";
$icon_sentiment_neutral: "sentiment_neutral";
$icon_sentiment_satisfied: "sentiment_satisfied";
$icon_sentiment_very_dissatisfied: "sentiment_very_dissatisfied";
$icon_sentiment_very_satisfied: "sentiment_very_satisfied";
$icon_settings: "settings";
$icon_settings_applications: "settings_applications";
$icon_settings_backup_restore: "settings_backup_restore";
$icon_settings_bluetooth: "settings_bluetooth";
$icon_settings_brightness: "settings_brightness";
$icon_settings_cell: "settings_cell";
$icon_settings_ethernet: "settings_ethernet";
$icon_settings_input_antenna: "settings_input_antenna";
$icon_settings_input_component: "settings_input_component";
$icon_settings_input_composite: "settings_input_composite";
$icon_settings_input_hdmi: "settings_input_hdmi";
$icon_settings_input_svideo: "settings_input_svideo";
$icon_settings_overscan: "settings_overscan";
$icon_settings_phone: "settings_phone";
$icon_settings_power: "settings_power";
$icon_settings_remote: "settings_remote";
$icon_settings_system_daydream: "settings_system_daydream";
$icon_settings_voice: "settings_voice";
$icon_share: "share";
$icon_shop: "shop";
$icon_shop_two: "shop_two";
$icon_shopping_basket: "shopping_basket";
$icon_shopping_cart: "shopping_cart";
$icon_short_text: "short_text";
$icon_show_chart: "show_chart";
$icon_shuffle: "shuffle";
$icon_signal_cellular_4_bar: "signal_cellular_4_bar";
$icon_signal_cellular_connected_no_internet_4_bar: "signal_cellular_connected_no_internet_4_bar";
$icon_signal_cellular_no_sim: "signal_cellular_no_sim";
$icon_signal_cellular_null: "signal_cellular_null";
$icon_signal_cellular_off: "signal_cellular_off";
$icon_signal_wifi_4_bar: "signal_wifi_4_bar";
$icon_signal_wifi_4_bar_lock: "signal_wifi_4_bar_lock";
$icon_signal_wifi_off: "signal_wifi_off";
$icon_sim_card: "sim_card";
$icon_sim_card_alert: "sim_card_alert";
$icon_skip_next: "skip_next";
$icon_skip_previous: "skip_previous";
$icon_slideshow: "slideshow";
$icon_slow_motion_video: "slow_motion_video";
$icon_smartphone: "smartphone";
$icon_smoke_free: "smoke_free";
$icon_smoking_rooms: "smoking_rooms";
$icon_sms: "sms";
$icon_sms_failed: "sms_failed";
$icon_snooze: "snooze";
$icon_sort: "sort";
$icon_sort_by_alpha: "sort_by_alpha";
$icon_spa: "spa";
$icon_space_bar: "space_bar";
$icon_speaker: "speaker";
$icon_speaker_group: "speaker_group";
$icon_speaker_notes: "speaker_notes";
$icon_speaker_notes_off: "speaker_notes_off";
$icon_speaker_phone: "speaker_phone";
$icon_spellcheck: "spellcheck";
$icon_star: "star";
$icon_star_border: "star_border";
$icon_star_half: "star_half";
$icon_stars: "stars";
$icon_stay_current_landscape: "stay_current_landscape";
$icon_stay_current_portrait: "stay_current_portrait";
$icon_stay_primary_landscape: "stay_primary_landscape";
$icon_stay_primary_portrait: "stay_primary_portrait";
$icon_stop: "stop";
$icon_stop_screen_share: "stop_screen_share";
$icon_storage: "storage";
$icon_store: "store";
$icon_store_mall_directory: "store_mall_directory";
$icon_straighten: "straighten";
$icon_streetview: "streetview";
$icon_strikethrough_s: "strikethrough_s";
$icon_style: "style";
$icon_subdirectory_arrow_left: "subdirectory_arrow_left";
$icon_subdirectory_arrow_right: "subdirectory_arrow_right";
$icon_subject: "subject";
$icon_subscriptions: "subscriptions";
$icon_subtitles: "subtitles";
$icon_subway: "subway";
$icon_supervisor_account: "supervisor_account";
$icon_surround_sound: "surround_sound";
$icon_swap_calls: "swap_calls";
$icon_swap_horiz: "swap_horiz";
$icon_swap_vert: "swap_vert";
$icon_swap_vertical_circle: "swap_vertical_circle";
$icon_switch_camera: "switch_camera";
$icon_switch_video: "switch_video";
$icon_sync: "sync";
$icon_sync_disabled: "sync_disabled";
$icon_sync_problem: "sync_problem";
$icon_system_update: "system_update";
$icon_system_update_alt: "system_update_alt";
$icon_tab: "tab";
$icon_tab_unselected: "tab_unselected";
$icon_tablet: "tablet";
$icon_tablet_android: "tablet_android";
$icon_tablet_mac: "tablet_mac";
$icon_tag_faces: "tag_faces";
$icon_tap_and_play: "tap_and_play";
$icon_terrain: "terrain";
$icon_text_fields: "text_fields";
$icon_text_format: "text_format";
$icon_textsms: "textsms";
$icon_texture: "texture";
$icon_theaters: "theaters";
$icon_thumb_down: "thumb_down";
$icon_thumb_up: "thumb_up";
$icon_thumbs_up_down: "thumbs_up_down";
$icon_time_to_leave: "time_to_leave";
$icon_timelapse: "timelapse";
$icon_timeline: "timeline";
$icon_timer: "timer";
$icon_timer_10: "timer_10";
$icon_timer_3: "timer_3";
$icon_timer_off: "timer_off";
$icon_title: "title";
$icon_toc: "toc";
$icon_today: "today";
$icon_toll: "toll";
$icon_tonality: "tonality";
$icon_touch_app: "touch_app";
$icon_toys: "toys";
$icon_track_changes: "track_changes";
$icon_traffic: "traffic";
$icon_train: "train";
$icon_tram: "tram";
$icon_transfer_within_a_station: "transfer_within_a_station";
$icon_transform: "transform";
$icon_translate: "translate";
$icon_trending_down: "trending_down";
$icon_trending_flat: "trending_flat";
$icon_trending_up: "trending_up";
$icon_tune: "tune";
$icon_turned_in: "turned_in";
$icon_turned_in_not: "turned_in_not";
$icon_tv: "tv";
$icon_unarchive: "unarchive";
$icon_undo: "undo";
$icon_unfold_less: "unfold_less";
$icon_unfold_more: "unfold_more";
$icon_update: "update";
$icon_usb: "usb";
$icon_verified_user: "verified_user";
$icon_vertical_align_bottom: "vertical_align_bottom";
$icon_vertical_align_center: "vertical_align_center";
$icon_vertical_align_top: "vertical_align_top";
$icon_vibration: "vibration";
$icon_video_call: "video_call";
$icon_video_label: "video_label";
$icon_video_library: "video_library";
$icon_videocam: "videocam";
$icon_videocam_off: "videocam_off";
$icon_videogame_asset: "videogame_asset";
$icon_view_agenda: "view_agenda";
$icon_view_array: "view_array";
$icon_view_carousel: "view_carousel";
$icon_view_column: "view_column";
$icon_view_comfy: "view_comfy";
$icon_view_compact: "view_compact";
$icon_view_day: "view_day";
$icon_view_headline: "view_headline";
$icon_view_list: "view_list";
$icon_view_module: "view_module";
$icon_view_quilt: "view_quilt";
$icon_view_stream: "view_stream";
$icon_view_week: "view_week";
$icon_vignette: "vignette";
$icon_visibility: "visibility";
$icon_visibility_off: "visibility_off";
$icon_voice_chat: "voice_chat";
$icon_voicemail: "voicemail";
$icon_volume_down: "volume_down";
$icon_volume_mute: "volume_mute";
$icon_volume_off: "volume_off";
$icon_volume_up: "volume_up";
$icon_vpn_key: "vpn_key";
$icon_vpn_lock: "vpn_lock";
$icon_wallpaper: "wallpaper";
$icon_warning: "warning";
$icon_watch: "watch";
$icon_watch_later: "watch_later";
$icon_wb_auto: "wb_auto";
$icon_wb_cloudy: "wb_cloudy";
$icon_wb_incandescent: "wb_incandescent";
$icon_wb_iridescent: "wb_iridescent";
$icon_wb_sunny: "wb_sunny";
$icon_wc: "wc";
$icon_web: "web";
$icon_web_asset: "web_asset";
$icon_weekend: "weekend";
$icon_whatshot: "whatshot";
$icon_widgets: "widgets";
$icon_wifi: "wifi";
$icon_wifi_lock: "wifi_lock";
$icon_wifi_tethering: "wifi_tethering";
$icon_work: "work";
$icon_wrap_text: "wrap_text";
$icon_youtube_searched_for: "youtube_searched_for";
$icon_zoom_in: "zoom_in";
$icon_zoom_out: "zoom_out";
$icon_zoom_out_map: "zoom_out_map";
@mixin material_icon_base() {
  font-family: $font-family-material;
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: middle;
  // Support for all WebKit browsers.
  -webkit-font-smoothing: antialiased;
  // Support for Safari and Chrome.
  text-rendering: optimizeLegibility;
  // Support for Firefox.
  -moz-osx-font-smoothing: grayscale;
  // Support for IE.
  font-feature-settings: "liga";
  //font-kerning: normal;
  //font-variant-ligatures: common-ligatures contextual;
  //font-feature-settings: "kern", "liga", "clig", "calt";
  color: $md-dark-icons-default;
  user-select: none;
  @include square(1em);
}

// For adding font icons to elements using CSS pseudo-elements
@mixin include-icon($icon: false, $asClass: false, $position: before, $addToBaseStyle: false) {
  @if $position == both {
    $position: "before, &::after";
  }
  // Either a :before or :after pseudo-element, or both, defaulting to :before
  &::#{$position} {
    @if $icon {
      @if $asClass == false {
        // A particular icon has been specified
        content: "#{$icon}";
      }
    }
    @if $addToBaseStyle {
      @extend .material-icon;
    }
    // Include any extra rules supplied for the pseudo-element
    @content;
  }
  @if $asClass {
    .icon_#{$icon} {
      &::#{$position} {
        @if $icon {
          // A particular icon has been specified
          content: "#{$icon}";
        }
        @if $addToBaseStyle {
          @extend .material-icon;
        }
        // Include any extra rules supplied for the pseudo-element
        @content;
      }
    }
  }
}
