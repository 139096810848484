// stylelint-disable
//
// GWT Base Widget Images
//
//urls
$url_mb_1: url(images/bulkedit-regex.png);
$url_mb_6: url(images/new/mb_folder_bundle_transparent_open_grey600_18dp.png);
$url_mb_11: url(images/new/mb_rename_grey600_18dp.png);
$url_mb_30: url(images/band-select-on.png);
$url_mb_31: url(images/band-select-off.png);
$url_mb_32: url(images/debug-hatching.png);
$url_mb_36: url(images/new/mb_search_bundle_grey600_18dp.png);
$url_mb_37: url(images/notify-base.png);
$url_mb_39: url(images/video-edit-clip-add-base.png);
$url_mb_42: url(images/preview-queue-base.png);
$url_mb_43: url(images/new/ic_local_movies_grey600_18dp.png);
$url_mb_44: url(images/new/mb_ws_add_grey600_18dp.png);
$url_mb_45: url(images/tick-circle-frame-base.png);
$url_mb_46: url(images/new/ic_open_with_grey600_18dp.png);
$url_mb_49: url(images/new/mb_search_key_grey600_18dp.png);
$url_mb_50: url(images/new/mb_rss_manage_grey600_18dp.png);
$url_mb_52: url(images/new/ic_cast_grey600_18dp.png);
$url_mb_53: url(images/new/mb_rss_create_grey600_18dp.png);
$url_mb_63: url(images/oneDarkPixel.png);
$url_mb_64: url(images/new/ic_reorder_grey600_18dp.png);
$url_mb_65: url(images/back-to-month-icon-base.png);
$url_mb_66: url(images/back-to-month-icon-hover.png);
$url_mb_67: url(images/back-to-month-icon-click.png);
$url_mb_69: url(images/email-base.png);
$url_mb_70: url(images/new/mb_ws_delete_grey600_18dp_red.png);
$url_mb_71: url(images/new/mb_ws_delete_grey600_18dp_white.png);
$url_mb_72: url(images/new/mb_ws_share_grey600_18dp.png);
$url_mb_73: url(images/new/ic_format_bold_grey600_18dp.png);
$url_mb_74: url(images/metaform-dl-icon-CS4.png);
$url_mb_75: url(images/metaform-dl-icon-CS3.png);
$url_mb_76: url(images/metaform-dl-icon-XMPR.png);
$url_mb_77: url(images/new/ic_add_circle_outline_grey600_18dp.png);
$url_mb_78: url(images/new/ic_note_add_grey600_18dp.png);
$url_mb_79: url(images/new/ic_archive_grey600_18dp.png);
$url_mb_80: url(images/negativeColor.png);
$url_mb_82: url(images/Loading-Dock-Overlay-Icon-base.png);
$url_mb_116: url(images/new/ic_language_grey600_18dp.png);
$url_mb_117: url(images/new/mb_folder_bundle_opaque_open_grey600_18dp.png);
$url_mb_118: url(images/FilmstripLeft.png);
$url_mb_119: url(images/FilmstripRight.png);
$url_mb_120: url(images/FilmstripUp.png);
$url_mb_121: url(images/FilmstripDown.png);
$url_mb_125: url(images/VideoTimelineLeft.png);
$url_mb_127: url(images/VideoTimelineRight.png);
$url_mb_130: url(images/TimecodeBottom.png);
$url_mb_131: url(images/TimecodeTop.png);
$url_mb_139: url(images/discussion.png);
$url_mb_140: url(images/discussion_hover.png);
$url_mb_141: url(images/discussion_down.png);
$url_mb_145: url(images/new/mb_folder_bundle_opaque_grey600_18dp.png);
$url_mb_149: url(images/new/ic_open_in_new_grey600_18dp.png);
$url_mb_150: url(images/new/ic_save_grey600_18dp.png);
$url_mb_160: url(images/new/mb_rss_attach_grey600_18dp.png);
$url_mb_164: url(images/new/mb_folder_bundle_info_grey600_18dp.png);
$url_mb_165: url(images/new/ic_replay_grey600_18dp.png);
$url_mb_166: url(images/new/ic_screen_rotation_grey600_18dp.png);
$url_mb_167: url(images/new/ic_crop_grey600_18dp.png);
$url_mb_168: url(images/new/ic_settings_grey600_18dp.png);
$url_mb_169: url(images/new/mb_widget_remove_grey600_18dp.png);
$url_mb_170: url(images/new/ic_autorenew_grey600_18dp.png);
$url_mb_171: url(images/new/ic_done_grey600_18dp.png);
$url_mb_172: url(images/new/ic_add_grey600_18dp.png);
$url_mb_173: url(images/cuepoint-code.png);
$url_mb_174: url(images/cuepoint-ad.png);
$url_mb_175: url(images/missing_placedImage.png);
