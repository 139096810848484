//
// Toast = MBToast
//
$mat-toast-padding: $list-group-item-padding-y $list-group-item-padding-x !default;
$mat-toast-min-width: 258px !default;
$mat-toast-max-width: 568px !default;
$mat-toast-spacing-margin: $spacer !default;
$mat-toast-bg: darken($md-gray-800, 6%);
.mb-toast-group {
  position: fixed;
  z-index: $zindex-tooltip * 3;
  bottom: 0;
  left: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  cursor: default;
}

.upload-status-dialog
{
  .mb-toast-group {
    bottom: 35px;
  }
}

.mb-toast-item {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  min-width: $mat-toast-min-width;
  max-width: $mat-toast-max-width;
  max-height: 168px;
  margin: $mat-toast-spacing-margin;
  padding: $mat-toast-padding;
  transition: $swift-ease-out;
  // Initial transformation is applied to start snack bar out of view, below its target position.
  // Note: it's preferred to use a series of transforms, instead of something like `calc()`, because
  // IE won't animate transforms that contain a `calc`.
  transform: translateY(100%) translateY($mat-toast-spacing-margin);
  color: $white;
  background-color: darken($md-gray-800, 6%);

  &.show {
    transform: translateY(0) translateY(0);
  }

  .badge {
    background-color: lighten($mat-toast-bg, 15%);
  }

  .notification-wrapper {
    background-color: darken($md-gray-800, 6%);

    .notification-title {
      padding: $mat-toast-padding;
      min-height: 32px;
      box-sizing: content-box;
    }

    .text-success {
      padding-left: 1.25rem;
      padding-right: 0;
    }

    .progress {
      border-radius: 0 !important;
    }
  }

  &.mb-upload-toast {
    max-width: initial !important;
    width: 350px;
    border-bottom: none;
    &.mb-upload-toast-success {
      width: fit-content;
    }
    .notification-wrapper {
      border: none;
    }
  }
}



//.d {
//  background-color: #404040;
//  border-radius: 3px;
//  box-shadow: 0 0 2px rgba(0,0,0,.12), 0 2px 4px rgba(0,0,0,.24);
//  color: #fff;
//  display: inline-block;
//  line-height: 20px;
//  margin-top: 8px;
//  padding: 16px;
//  transition: opacity 200ms,-webkit-transform 300ms cubic-bezier(0.165,0.840,0.440,1.000);
//  white-space: nowrap;
//  z-index: 45;
//  transform: translateY(40px);
//}
