//
// Custom Form Imports
//
//@import "custom_forms/material_checkboxes";
@import "custom_forms/material_radios";
//
// Check Box
//
$mb_radio_check_input_padding: $input-btn-padding-y $input-btn-padding-x $input-btn-padding-y ($input-btn-padding-x * 4);
@mixin mb-radio-check-active($material-icon, $color: theme-color("primary"), $hover-bg: $color) {
  &::before {
    color: $color;
    content: $material-icon;
  }
  &:active {
    &::after {
      background-color: rgba($hover-bg, 0.24);
    }
  }
}

.mb-checkbox,
.mb-radio {
  min-height: 36px;

  input[type="checkbox"],
  input[type="radio"] {
    @extend .custom-control-input;
  }

  .x-menu-item-icon {
    display: none;
  }

  .list-group-checkboxes & {
    margin-bottom: -1px;
    padding: ($list-group-item-padding-y / 2) ($list-group-item-padding-x / 2) ($list-group-item-padding-y / 2)
      ($list-group-item-padding-x * 2);

    &.active {
      color: $list-group-action-color;
      border-color: $list-group-border-color;
      background-color: $list-group-bg;
    }
  }
}

.mat-input-block {
  display: block;
  margin: 0;

  .lever {
    display: block !important;
  }
}

.mb-checkbox,
.mb-radio label {
  font-size: $font-size-base;
  line-height: $input-btn-line-height;
  position: relative;
  display: block;
  margin-bottom: 0;
  padding: $input-btn-padding-y $input-btn-padding-x $input-btn-padding-y ($input-btn-padding-x * 4);
  cursor: pointer;

  &::before {
    z-index: 2;
    left: 10px;
    color: $md-dark-icons-default;
    @include absolute-centered-y($default-icon-font-size);
  }

  &::after {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    margin-top: -20px;
    content: "";
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1), opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: scale(0);
    opacity: 0;
    @include size(40px);
    border: 1px dotted transparent;
    border-radius: 5rem;
  }

  &:active {
    z-index: 2; // Place active items above their siblings for proper border styling
    &::after {
      z-index: 3;
      transition-delay: 150ms;
      transform: scale(1);
      opacity: 1;
      background-color: rgba($black, 0.12);
    }
  }

  &.disabled,
  &:disabled,
  .mb-radio-disabled & {
    opacity: $disabled-opacity;
    @include box-shadow(none);

    &::after {
      display: none;
    }
  }

  .text-white &,
  .bg-primary & {
    &.active {
      &::after {
        display: block;
        transform: scale(0.9);
        opacity: 1;
        background-color: $white;
      }
    }
  }
}

.mb-checkbox-flush {
  padding-left: 2rem;

  &::before {
    left: -2px;
  }

  &::after {
    left: -11px;
  }
}

.mb-checkbox-default {
  &::after {
    transition: none;
    transform: scale(1);
  }
}

.mb-checkbox {
  &.x-form-invalid {
    border: 1px solid $form-feedback-invalid-color !important;
  }

  &.card-body-checkbox {
    // 1.25rem
    padding: 0 0 0 $input-btn-padding-x * 4;
  }

  &.dropdown-item {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x $dropdown-item-padding-y $dropdown-item-padding-x +
      (2.375);

    &::before {
      left: $dropdown-item-padding-x;
    }

    &::after {
      left: 15px;
    }

    &:active,
    &.active {
      color: $dropdown-link-hover-color;
      background-color: $dropdown-link-hover-bg;
    }
  }

  &::before {
    content: $icon_check_box_outline_blank;
  }

  &.active,
  .x-menu-item-checked & {
    @include mb-radio-check-active($icon_check_box);
  }

  &.mb-radio {
    &::before {
      content: $icon_radio_button_unchecked;
    }

    &.active,
    .x-menu-item-checked & {
      @include mb-radio-check-active($icon_radio_button_checked);
    }
  }
}

.mb-radio {
  display: block;

  label {
    &::before {
      content: $icon_radio_button_unchecked;
    }
  }

  input[type="radio"] {
    &:checked {
      ~ label {
        @include mb-radio-check-active($icon_radio_button_checked);
      }
    }
  }
}

@include custom-range("light", $md-white-lighter);
@include custom-range("red", rgba($md-red-a400, 0.25));
@include custom-range("green", rgba($md-green-a400, 0.25));
@include custom-range("blue", rgba($md-blue-a400, 0.25));
@include custom-range-thumb("red", $md-red-a400);
@include custom-range-thumb("green", $md-green-a400);
@include custom-range-thumb("blue", $md-blue-a400);
