//
// Super Dev Mode
//
.SuperDevDialog {
  position: fixed;
  z-index: $zindex-modal * 3;
  top: inherit !important;
  bottom: $spacer;
  left: $spacer;
  min-width: 1rem;

  .btn + .btn {
    margin-left: 0.25rem;
  }
}

.SuperDevOverlay {
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
