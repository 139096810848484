.admin-ui {
  .FieldEditPanel {
    > .MBToolBar {
      .btn {
        display: none;
      }
    }
  }
	.ui-metaform-rule-disclosure-panel {
		.SearchCriteriaPanel-item
		{
			flex-wrap: nowrap!important;
		}
	}
}
