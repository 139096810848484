//
// Modals
//
.modal-backdrop-dnd {
  //z-index: 1000;
  position: fixed;
  z-index: $zindex-modal-backdrop;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  //background-color: $modal-backdrop-bg;
  //opacity: .5;
}

.modal-header-wf,
.modal-footer-wf {
  position: relative;
  z-index: 2;
  border: none;
}

.modal-header-wf {
  @extend .wf-1;
}

.modal-footer-wf {
  box-shadow: 0 2px 3px 3px rgba($black, 0.2);
}

//.modal-dialog-scroll {
//
//  // DO NOT REMOVE
//  &,
//  .modal-dialog {
//    pointer-events: none;
//  }
//}
.WikiLayoutPanel {
  .CodeMirror {
    height: 600px;
  }
}

.modal-black {
  .modal-content {
    background-color: $black;
  }

  .modal-header {
    border-color: $black;

    .btn-icon {
      @extend .btn-icon-inverse !optional;

      .material-icon {
        color: $md-light-icons-default;
      }
    }
  }

  .smallAssetDisplay {
    .imageWrapper {
      img {
        transition: $swift-ease-out;

        &.selected {
          transform: scale(0.85);
          box-shadow: 0 0 0 6px theme-color("primary");
        }
      }
    }
  }
}

.ViewLogPanel {
  .input-group {
    width: 100% !important;

    .form-control {
      // Add width 1% and flex-basis auto to ensure that button will not wrap out
      // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
      width: 1% !important;
    }
  }
}

.modal {
  &.top-dialog {
    z-index: ($zindex-modal + 1);
  }
}

.modal-backdrop {
  &.top-dialog {
    z-index: $zindex-modal;
  }
}

.modal-content,
.modal {
  & ~ .dropdown-menu {
    z-index: $zindex-tooltip !important;
  }
}

.modal-footer,
.modal-header {
  flex-shrink: 0;
}

.modal-alert {
  .modal-header,
  .modal-footer,
  .modal-body {
    padding-right: ($modal-inner-padding * 1.5) !important;
    padding-left: ($modal-inner-padding * 1.5) !important;
    border: none;
  }
}

.modal-body {
  > *:only-child {
    margin-bottom: 0 !important;
  }
}

.modal-dialog-centered {
  flex-direction: inherit;
}

.modal-content-flex-fill {
  .modal-content {
    flex: 1 1 auto;
  }

  .modal-open & {
    overflow: hidden;
  }
}

.modal-body-container-fluid-fix {
  width: 100%;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-right: ($grid-gutter-width / 2) !important;
  padding-left: ($grid-gutter-width / 2) !important;
}

.modal-content-no-wf {
  .modal-content {
    box-shadow: none !important;
  }
}

.modal-view-resize-popup {
  height: auto;
  border: 3px solid $md-white-darker;
  background-color: $white;

  .modal-dialog {
    padding: 0 !important;
  }
}

.overlay_box_edit {
  overflow: auto;
  resize: both;

  .annotation_text {
    display: none;
  }
}

.min_width_third {
  min-width: 33.33vw;
}

@include media-breakpoint-up(sm) {
  .modal-larger-padding {
    .modal-dialog {
      padding: ($modal-dialog-margin-y-sm-up * 2) 0 !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  .modal-title {
    font-size: $font-size-base;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

//
.modal-xl {
  max-width: $modal-xl !important;
  padding-right: $modal-dialog-margin-y-sm-up !important;
  padding-left: $modal-dialog-margin-y-sm-up !important;
}

.modal-xxl {
  max-width: $modal-xxl !important;
  padding-right: $modal-dialog-margin-y-sm-up !important;
  padding-left: $modal-dialog-margin-y-sm-up !important;
}

.modal-full {
  &,
  .modal-content {
    width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;
    max-height: 100vh !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 0;
  }
}

.modal {
  display: block;
  contain: strict;

  &.top-dialog {
    z-index: ($zindex-modal + 1);
  }
}

.modal-dialog {
  transform: none !important;
}

.modal-dialog-scrollable {
  .modal-body {
    min-height: 0;

    &:not(.overflow-hidden) {
      @include pretty-scroll-mixin(true, false);
    }
  }
}
