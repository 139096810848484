//
// Directory Tree Widget
//
.card-directory-tree {
  &.DropZone-dragging {
  }
}

// Disable GWT closing animation for consistency
.directory-tree-item {
  > div {
    height: 0% !important;
  }

  &.tree-item-open > div {
    height: 100% !important;
  }
}
