//
// GXT Tab Panel
//
.x-tab-strip {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.x-tab-edge {
  display: none;
}

.x-tab-strip-inner {
  font-style: normal;
}

.x-tab-right {
  font-weight: 500;
  position: relative;
  display: block;
  min-width: 130px;
  padding: $nav-link-padding-y $nav-link-padding-x;
  cursor: pointer;
  user-select: none;
  transition: $default-transition;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  color: $navbar-light-color;

  &::after {
    position: absolute;
    right: 2px;
    bottom: -1px;
    left: 2px;
    height: 3px;
    content: "";
  }

  @include hover-focus {
    text-decoration: none;
    color: $navbar-light-hover-color;
    outline: none;
    &::after {
      background-color: $nav-tabs-border-color;
    }
  }
  // Disabled state lightens text
  &.disabled {
    color: $navbar-light-disabled-color;
  }

  .x-tab-strip-active & {
    color: $navbar-light-active-color;

    &::after {
      background-color: theme-color("primary");
    }
  }
}

.x-tab-panel-header {
  padding: $modal-inner-padding / 2 $modal-inner-padding / 2 0;
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
}

.x-tab-panel-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when should there be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  padding: $modal-inner-padding;
}
