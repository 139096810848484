//
// View Widget
//
.toggleButtons {
  .Facebook,
  .YouTube,
  .Twitter {
    transform: scale(0.6);
    background-size: cover;

    .material-icon {
      display: none;
    }
  }

  .Facebook {
    // background-image: url(../servlet/ThumbDisplay?id=5DA510C1-AAA1-413E-BCF7-45A06D40E1A7) !important;
  }

  .YouTube {
    //background-image: url(../servlet/ThumbDisplay?id=8BB6C614-3A0E-4B45-9B5C-D001F1E5F77C);
  }

  .Twitter {
    // background-image: url(../servlet/ThumbDisplay?id=711DDB3A-7DEA-41C5-A7E5-43B319C731E4);
  }
}
