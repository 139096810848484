// stylelint-disable selector-no-qualifying-type
//
// Switch
//
.mb-switch {
  @extend .form-group;
  line-height: 1.5;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);

  &:focus {
    outline: none;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 14px;
  margin: 0 16px;
  content: "";
  transition: background 0.3s ease;
  vertical-align: middle;
  border-radius: 5rem;
  background-color: $md-gray-400;

  .card-header & {
    margin-right: 0;
  }

  &::before,
  &::after {
    position: absolute;
    top: -3px;
    left: 0;
    @include size(20px);
    display: inline-block;
    content: "";
    transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease, transform 0.1s ease;
    border-radius: 5rem;
  }

  //&::before {
  //  background-color: $md-gray-500;
  //}
  &::after {
    background-color: $md-gray-50;
    box-shadow: 0 1px 3px rgba($black, 0.4);
  }

  &:active {
    &::before {
      transform: scale(2.4);
      background-color: rgba($md-blue-a200, 0.3);
    }
  }

  .is-open > .card-header > div > &,
  .active & {
    background-color: rgba($md-blue-a200, 0.3);

    &::before,
    &::after {
      left: 18px;
    }

    &:active {
      &::before {
        transform: scale(2.4);
        background-color: $md-black-lighter;
      }
    }

    &::after {
      background-color: $md-blue-a200;
    }
  }
}
