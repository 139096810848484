//
// Notification Status / Status Bar
//
.status-dialog {
  font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
  position: fixed;
  z-index: $zindex-dropdown;
  right: $spacer;
  //display: none; // none by default, but block on "open" of the menu
  bottom: $spacer;
  width: 350px;
  //margin: 0 0 0 $spacer; // override default ul
  padding: 0;
  list-style: none;
  transition: all 0.15s $mat-fast-out-slow-in-timing-function;
  transform: translate3d(0, 0, 0);
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  color: $body-color;
  @include border-radius($dropdown-border-radius);
  //transform: translate3d(0, 24px, 0);
  background-color: $dropdown-bg;
  background-clip: padding-box;

  &.closed {
    width: 300px;
    transform: translate3d(0, $spacer, 0);

    .LoadingDockNotificationWindow,
    .NotificationWindow {
      display: none;
    }

    .btn-notification-toggle {
      .material-icon {
        transform: rotate(180deg) scale(1.5);
      }
    }
  }

  .scale-down-center {
    -webkit-animation: scale-down-center 0.4s ease-out;
    animation: scale-down-center 0.4s ease-out;
  }

  @-webkit-keyframes scale-down-center {
    0% {
      -webkit-transform: scale(1.4);
      transform: scale(1.4);
    }
    50% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes scale-down-center {
    0% {
      -webkit-transform: scale(1.4);
      transform: scale(1.4);
    }
    50% {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

.notification-bar-header {
  padding: $card-spacer-y $card-spacer-x;
  background-color: darken($md-gray-800, 5%);
}

.tippy-tooltip {
  @include border-radius($dropdown-border-radius);
  overflow: hidden;
}

.dropdown-menu-notification-center {
  .tippy-tooltip {
    overflow: hidden;
    width: 450px;
    max-width: 450px !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.list-group-notification-item {
  @include hover-focus {
    .btn-notification-item-action {
      opacity: 1;
    }
  }

  .upload-notification-image {
    overflow: visible;
  }
}

.image-stack {
  &.three {
    :nth-child(1) {
      z-index: 4;
      top: -5px;
      left: -5px;
    }
    :nth-child(2) {
      z-index: 3;
    }
    :nth-child(3) {
      z-index: 2;
      top: 5px;
      left: 5px;
    }
  }

  &.two {
    :nth-child(1) {
      z-index: 4;
      top: -2px;
      left: -2px;
    }
    :nth-child(2) {
      z-index: 2;
      top: 3px;
      left: 3px;
    }
  }
}

.notification-badge-count {
  top: 2px;
  left: 10px;
  line-height: 1.4;
}

.sticky-notification .alert {
  height: 50px;
}

.btn-notification-item-action {
  opacity: 0;
}

.notification-center-header {
  z-index: 10;
  padding: 0.75rem 1rem;
}

.MessageCenterMenu {
  .delete-icon {
    top: 4px;
    left: 4px;
    transform: scale(1.5);
  }
}

.message-center-header {
  position: relative;
  z-index: 5;
  padding: 0.5rem 0.75rem;
}

.MessageCenterButton {
  .badge {
    font-size: 60%;
    z-index: 1;
    top: 6px !important;
    right: 6px !important;
    background-color: $form-feedback-invalid-color;
  }
}
