.popover {
  font-size: $popover-font-size;
  position: absolute;
  z-index: $zindex-popover;
  top: 0;
  left: 0;
  display: block;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  max-width: $popover-max-width;
  // Allow breaking very long words so they don't overflow the popover's bounds
  word-wrap: break-word;
  border: $popover-border-width solid $popover-border-color;
  background-color: $popover-bg;
  background-clip: padding-box;
  @include border-radius($popover-border-radius);
  @include box-shadow($popover-box-shadow);

  .arrow {
    position: absolute;
    display: block;
    width: $popover-arrow-width;
    height: $popover-arrow-height;
    margin: 0 $border-radius-lg;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      border-style: solid;
      border-color: transparent;
    }
  }
}

// Offset the popover to account for the popover arrow
.popover-header {
  font-size: $font-size-base;
  margin-bottom: 0; // Reset the default from Reboot
  padding: $popover-header-padding-y $popover-header-padding-x;
  color: $popover-header-color;
  border-bottom: $popover-border-width solid darken($popover-header-bg, 5%);
  background-color: $popover-header-bg;
  $offset-border-width: calc(#{$border-radius-lg} - #{$popover-border-width});
  @include border-top-radius($offset-border-width);

  &:empty {
    display: none;
  }
}

.popover-body {
  padding: $popover-body-padding-y $popover-body-padding-x;
  color: $popover-body-color;
}
