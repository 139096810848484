// Magazine
//
.magazine-viewport {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 65%;

  .next-button {
    position: absolute;
    top: 0;
    right: -22px;
    width: 22px;
    height: 600px;
    border-radius: 0 15px 15px 0;
  }

  .previous-button {
    position: absolute;
    top: 0;
    left: -22px;
    width: 22px;
    height: 600px;
    border-radius: 15px 0 0 $spacer;
  }

  .magazine-container {
    position: absolute;

    left: 50%;
    width: 922px;
    height: 600px;
    margin: auto;
    &:not(.zoom-in) {
      display: flex;
      align-items: center;
    }
  }

  .magazine {
    left: -461px;
    width: 922px;
    height: 600px;
  }

  .page {
    width: 461px;
    height: 600px;
    background: $white no-repeat;
    background-size: 100% 100%;

    img {
      margin: 0;
      user-select: none;
    }
  }

  .zoom-in {
    .region,
    .next-button,
    .previous-button,
    .even .gradient,
    .odd .gradient {
      display: none;
    }
  }

  &.zoom-in {
    height: 100% !important;
  }

  .zoomer .region {
    display: none;
  }
}

.magazine-viewport .zoomin-button-container {
  position: absolute;
  top: 50%;
  margin-top: -40px;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  .previous-button {
    left: -15px;
    &:after {
      content: "chevron_left";
    }
  }

  .next-button {
    right: -15px;
    &:after {
      content: "chevron_right";
    }
  }

  .next-button,
  .previous-button {
    background-color: hsla(0, 0%, 78%, 0.4);
  }

  .next-button-hover,
  .previous-button-hover {
    background-color: hsla(0, 0%, 78%, 0.5) !important;
  }

  .next-button-down,
  .previous-button-down {
    background-color: hsla(0, 0%, 78%, 0.7) !important;
  }
}

.zoomin-button-container .next-button,
.zoomin-button-container .previous-button {
  position: absolute;
  width: 56px !important;
  height: 56px !important;
  border-radius: 28px;
}

.last:not(.first) .magazine {
  margin-left: 0 !important;
}

.first:not(.last) .magazine {
  right: 0 !important;
  margin-left: unset !important;
}

.magazine {
  .region {
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    opacity: 0.2;
    border-radius: 10px;
    background: $pink;

    &:hover {
      opacity: 0.5;
    }

    &.zoom {
      opacity: 0.01;

      &:hover {
        opacity: 0.2;
      }
    }
  }
}

.magazine-thumbnails {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(30% - 20px);
  padding-bottom: 20px;
  @extend .pretty-scroll !optional;

  > div {
    height: 100%;
  }

  ul {
    margin: 0;
    padding: 0;
    transition: transform 0.1s ease-in-out;
    text-align: center;

    li {
      width: 100px;
      padding: 4px;

      &:hover {
        background-color: $md-gray-100;

        span {
          font-weight: $font-weight-bold;
          line-height: $spacer;
          position: absolute;
          z-index: 2;
          bottom: -15px;
          left: 0;
          display: block;
          width: 100%;
          height: $spacer;
          cursor: default;
          color: $color_storm_dust_approx;
          border-radius: 0 0 5px 5px;
          background-color: $md-gray-100;
        }
      }

      &.i {
        div {
          img {
            width: 50px;
          }
        }
      }

      &.d {
        .d-flex {
          div {
            width: 50px;
          }
        }
      }
    }
  }

  li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    list-style: none;
    transition: 60ms;

    span {
      display: none;
    }
  }

  .current {
    background-color: theme-color("primary") !important;

    > span {
      font-weight: $font-weight-bold;
      line-height: $spacer;
      position: absolute;
      z-index: 2;
      bottom: -15px;
      left: 0;
      display: block;
      width: 100%;
      height: $spacer;
      cursor: default;
      color: $white !important;
      border-radius: 0 0 5px 5px;
      background-color: theme-color("primary") !important;
    }
  }

  //img {
  //  float: left;
  //  width: 50px;
  //  height: auto;
  //}
}

.last .actionContainer {
  right: 400px;
}

.actionContainer {
  position: absolute;
  top: 5px;
  right: -73px;
  padding: 1px;
  background-color: hsla(0, 0%, 100%, 0.8);
}

.magazine-viewport .magazine-container {
  position: absolute;
  left: 50%;
  width: 922px;
  height: 600px;
  margin: auto;
}

.ext-gecko .magazine-viewport .magazine-container,
.ext-safari .magazine-viewport .magazine-container {
  top: 0;
  bottom: 0;
}

.placedImages .contain {
  width: 80px;
  height: 80px;
}

.magazine-viewport .magazine {
  left: -461px;
  width: 922px;
  height: 600px;
}

.magazine-viewport .zoom-in .page {
  cursor: zoom-out;
}

.magazine-viewport .page {
  width: 461px;
  height: 600px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: zoom-in;
}

.magazine-viewport .zoom-in .region,
.magazine-viewport .zoomer .region {
  display: none;
}

.magazine .region {
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.2;
  border-radius: 10px;
  background: #06f;
}

.magazine .region:hover {
  opacity: 0.5;
}

.magazine .region.zoom {
  opacity: 0.01;
}

.magazine .region.zoom:hover {
  opacity: 0.2;
}

.magazine-viewport .page img {
  margin: 0;
  user-select: none;
}

//.magazine .even .gradient {
//  background-image: linear-gradient(90deg, rgba(0, 0, 0, .2) 95%, transparent);
//  background-repeat: repeat-x;
//}
//
//.magazine .even .gradient,
//.magazine .odd .gradient {
//  height: 100%;
//  left: 0;
//  position: absolute;
//  top: 0;
//  width: 100%;
//}
//
//.magazine .odd .gradient {
//  background-image: linear-gradient(90deg, rgba(0, 0, 0, .15) 95%, transparent);
//  background-repeat: repeat-x;
//}
.magazine-viewport {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 65%;

  & .button-holder {
    position: absolute !important;
    z-index: 5;
    bottom: 0;
    transition: opacity 0.25s;
    opacity: 0;
  }

  &:hover .button-holder {
    opacity: 1;
  }
}

.magazine-viewport.zoom-in {
  height: 100% !important;
}

.magazine-viewport .zoom-in .even .gradient,
.magazine-viewport .zoom-in .odd .gradient {
  display: none;
}

.magazine-viewport .loader {
  position: absolute;
  top: 280px;
  left: 219px;
  width: 22px;
  height: 22px;
}

.magazine-viewport .next-button,
.magazine-viewport .previous-button {
  position: absolute;
  top: 0;
  width: 22px;
  height: 600px;
}

.magazine-viewport .next-button {
  right: -22px;
}

.magazine-viewport .previous-button {
  left: -22px;
}

.magazine-viewport {
  .first {
    .previous-button {
      display: none !important;
    }
  }

  .last {
    .next-button {
      display: none !important;
    }
  }

  .previous-button,
  .next-button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:before {
      @extend .material-icon;
      font-size: 24pt;
      color: #fff;
    }
  }

  .next-button {
    &:before {
      content: "chevron_right";
    }
  }

  .previous-button {
    &:before {
      content: "chevron_left";
    }
  }

  .next-button,
  .previous-button {
    background-color: hsla(0, 0%, 78%, 0.4);
  }

  .next-button-hover,
  .previous-button-hover {
    background-color: hsla(0, 0%, 78%, 0.5);
  }

  .next-button-down,
  .previous-button-down {
    background-color: hsla(0, 0%, 78%, 0.7);
  }
}

.magazine-viewport .zoom-in .next-button,
.magazine-viewport .zoom-in .previous-button {
  display: none !important;
}

.magazine-thumbnail-list {
  //display: flex;
  //justify-content: center;
  //align-items: center;
}

.magazine-thumbnails {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  height: calc(30% - 20px);
  padding-bottom: 20px;

  > div {
    height: 100%;
  }

  ul {
    padding-left: 0;
    list-style: none;
    transition: transform 100ms ease-in-out;
    text-align: center;
  }

  li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    list-style: none;
    transition: 60ms;

    span {
      display: none;
    }
  }
}

.thumbnails-touch {
  ul {
    transform: none;
  }
}

.magazine-thumbnails ul li:hover {
  background-color: #e9e9e9;
}

.magazine-thumbnails .current,
.magazine-thumbnails .current > span {
  background-color: theme-color("primary");
}

.magazine-thumbnails .current > span {
  color: $white !important;
}

.magazine-thumbnails ul li.i {
  width: 54px;
}

.magazine-thumbnails ul li {
  width: 100px;
  padding: 4px;
}

.thumbnails-touch ul li:hover {
  transform: none;
}

.placedImageHolder {
  display: block;
}

.placedImage {
  width: 75px;
  height: 75px;
}

.placedImage img {
  margin-bottom: 15px;
}

.filename,
.magazine-thumbnails .current > span,
.magazine-thumbnails ul li:hover span {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 0.9375rem;
  position: absolute;
  z-index: 2;
  bottom: -15px;
  left: 0;
  display: block;
  width: 100%;
  height: 15px;
  cursor: default;
  color: #666;
  border-radius: 0 0 5px 5px;
  background-color: #f5f5f5;
}

.filename {
  bottom: 4px !important;
  overflow: hidden;
  width: 89px;
  text-overflow: ellipsis;
}

.magazine-thumbnails img {
  //float: left;
  //height: auto;
  //width: 50px;
}

.esc {
  position: absolute;
  z-index: 10000;
  top: 10px;
  left: 0;
  width: 100%;
  height: 40px;
}

//.AnnotationButton,
//.esc > div,
//.filename,
//.made,
//.magazine-thumbnails ul li span {
//  font-family: "Roboto", Lucida Grande, Arial, Helvetica, sans-serif;
//  font-feature-settings: 'liga';
//  text-rendering: optimizeLegibility;
//}
.esc > div {
  font-size: 0.75rem;
  line-height: 1.875rem;
  width: 140px;
  height: 30px;
  margin: auto;
  color: #fff;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
}

.esc > div,
.made {
  text-align: center;
}

.made {
  font-size: 0.875rem;
  line-height: 2.5rem;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  color: #666;
}

.get-now {
  margin: 0;
  padding: 5px 20px;
  text-decoration: none;
  color: $white;
  border: 1px solid $color_chalet_green_approx;
  border-radius: 20px;
  background-image: linear-gradient(
    180deg,
    $color_asparagus_approx 0,
    $color_dingley_approx 50%,
    $color_fern_green_approx 51%,
    $color_woodland_approx
  );
  box-shadow: inset 0 1px 1px $color_feijoa_approx, inset 0 -1px 1px $color_tom_thumb_approx;
  text-shadow: 0 -1px 0 $color_woodland_approx;

  &:hover {
    text-decoration: none;
    border: 1px solid $color_woodland_approx;
    background-image: linear-gradient(180deg, $color_olive_drab_approx, $color_chalet_green_approx);
  }

  &:active {
    border: 1px solid $color_black_olive_approx;
    background-image: linear-gradient(180deg, $color_chalet_green_approx, $color_tom_thumb_approx);
  }
}

.get-now {
  margin: 0;
  padding: 5px 20px;
  text-decoration: none;
  color: #fff;
  border: 1px solid #4d682b;
  border-radius: 20px;
  background: linear-gradient(180deg, #82a353, #62843c 50%, #5b7e32 51%, #476928);
  background-color: #657948;
  box-shadow: inset 0 1px 1px #9ecf7a, inset 0 -1px 1px #355828;
  text-shadow: 0 -1px 0 #3d561f;
}

.get-now:hover {
  text-decoration: none;
  border: 1px solid #3f5228;
  background: linear-gradient(180deg, #688939, #5b6d40);
}

.get-now:active {
  border: 1px solid #243017;
  background: linear-gradient(180deg, #577330, #4c5b35);
}

.thumbnails-rtl-direction {
  float: right;
  margin: 5px 10px 0 5px !important;
}

.VideoFrameInfo,
.VideoFrameRow {
  padding: 5px;
}

.VideoFrameTime {
  font-style: italic;
  line-height: 3.75rem;
}

.VideoFrameImageWrapper {
  position: relative;
  margin: 5px;
}

.VideoFrameFoundText {
  font-weight: bold;
  display: inline;
}

.VideoFrameText {
  display: inline;
}

.imageHolder .AnimationPanel,
.imageHolder .AudioPanel {
  width: calc(100% - 56px);
  height: calc(100% - 56px);
  margin: 28px;
}

.imageHolder .AnimationPanel img {
  width: 100% !important;
  // override inline styling for asset view
  height: 100% !important;
  object-fit: contain;
}

.AudioPanel {
  position: absolute !important;
  height: auto;
}

.audioImage,
.AudioPanel,
.AudioPanel audio {
  width: 100%;
}

.audioImage {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
