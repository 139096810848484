//
// Cards
//
.card {
  &[class^="wf-"],
  &[class*=" wf-"] {
    &:not(.card-selectable) {
      border: none;
    }

    &.card-selectable {
      border-color: transparent;
    }
  }
  border-radius:$border-radius-xl;
}

.card-group {
  &[class^="wf-"],
  &[class*=" wf-"] {
    > .card {
      border-top: 0;
      border-bottom: 0;

      &:first-child {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
}

.card-normalize-styles.titleless,
.card-content-holder-widget.titleless {
  .no-admin-mode & {
    &,
    .card-header,
    .card-footer {
      border: 0;
      border-radius: 0;
      background-color: transparent;
    }

    .btn-control-prev {
      left: 0;
    }

    .btn-control-next {
      right: 0;
    }
  }
}

.card,
.card-header,
.card-sub-header,
.card-dot-paging-panel,
.card-body,
.card-footer {
  &:empty {
    display: none;
  }
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: inherit;
}

.xmp-tab-asset-container {
  padding: 0;

  .card-img-top {
    margin-bottom: 0.5rem;
  }

  .card-grid-box {
    font-size: $font-size-sm;

    .card-body {
      padding: 0 0.5rem;

      &:last-child {
        padding-bottom: 0.5rem;
      }
    }
  }
}

.AssetDisplayCard:hover .meta-holder-hover {
  opacity: 1;
}

.card-loading-mask {
  top: auto !important;
  left: auto !important;
}

.BulkEdit {
  .col-field-label {
    font-weight: $font-weight-base;
  }
}

.card-img-left-contain {
  border-right: $card-border-width solid $card-border-color;
  background-color: darken($card-bg, 3%);
}

.card-img-top-contain {
  border-bottom: $card-border-width solid $card-border-color;
  background-color: darken($card-bg, 3%);
}

.infinite-scroll-item-fields,
.card-grid-box-body {
  font-size: $font-size-sm;

  > div:first-child:not(:only-child) {
    &.additional-field,
    .cursor-pointer {
      font-weight: $font-weight-bold;
    }
  }

  > div:not(:first-child) {
    font-size: 90%;
    opacity: 0.9;
  }
}

.card-grid-box {
  overflow: hidden;
  height: 100%;
  @include mat-elevation(2);
  border: none;

  &.active {
    transform: scale(0.925);
    @include mat-elevation(10);
  }

  &:hover {
    .bg-hover-gradient {
      opacity: 0.5;
    }
  }

  .band-select-is-dragging &,
  .table-tile-view:hover & {
    @include mat-elevation-transition();
    backface-visibility: hidden;
  }

  .dragdrop-draggable {
    * {
      user-select: none;
    }
  }
}

.card-set-flush {
  .card {
    border-width: 1px 0 0;

    &:only-child {
      border: none;
    }
  }

  .card-header {
    border: none;
    background-color: transparent;
  }
}

.card-sub-header {
  position: relative;
}

.card-selectable {
  // Share hover and focus styles
  @include hover-focus {
    background-color: $md-gray-50;
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
  }

  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    border-color: theme-color("primary");
    background-color: $component-active-bg-light;
    //background-image: none;
    //@include box-shadow($btn-active-box-shadow);
    //
    //&:focus {
    //  @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    //}
  }

  .mat-radio {
    .lever {
      font-size: $default-font-size-sm;
      padding: 0;

      &::before,
      &::after {
        display: none;
      }
    }
  }
}

.WorkSpacePanel {
  .card {
    &.active {
      transform: scale(0.95);
    }
  }
}

.card-deck-target {
  .card {
    display: flex;
    // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
    flex: 1 0 0%;
    flex-direction: column;
  }
}

.card-body-sm {
  padding: $card-spacer-y $card-spacer-x;
}

.card-accordion-body {
  &.is-loading {
    .EmptyFieldToggle {
      opacity: 0;
    }
  }
}

.card-view-widget {
  //overflow: hidden;
  .no-admin-mode & {
    &.minimized {
      display: none;
    }

    &.active-view-widget {
      display: flex;

      > .BaseWidget-handle {
        display: flex;
      }

      &.drop-zone-valid {
        z-index: auto !important;
      }
    }

    &.titleless {
      margin-bottom: 0;

      > .BaseWidget-handle {
        display: none;
      }
    }

    &,
    .card-header,
    .card-footer {
      border: none;
      background-color: transparent;
    }
  }

  .card-sub-header {
    min-height: 33px;
  }
}

.card-vertical-widget {
  margin-bottom: ($spacer / 2);

  &.d-none {
    margin-bottom: 0;
  }

  //.logged-in & {
  //  margin-bottom: ($spacer / 2);
  //
  //  //> .card-body {
  //  //  padding: 0.5rem !important;
  //  //}
  //}
  .form-group-field-list {
    flex-direction: row;
    margin: 0;
  }

  .col-field-label {
    font-size: $font-size-sm;
  }

  .FieldEditPanel {
    &.NotEdited {
      .MBToolBar {
        display: none;
      }

      .EditFieldContentPanel {
        margin-bottom: 0.5rem;
      }
    }
  }

  .col-field-label,
  .col-field-form-element {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-field-form-element {
    margin-bottom: 0;
  }

  .no-admin-mode & {
    &.titleless {
      //margin-bottom: 0;
      > .BaseWidget-handle {
        display: none;
      }
    }

    &.toggleable {
      &,
      > .BaseWidget-handle {
        display: flex;
      }
    }
  }
  >.card-body {
    border-radius: 0 0 $border-radius-xl $border-radius-xl;
  }
  > .BaseWidget-handle {
    position: relative;
    //padding-left: ($card-spacer-x / 2);
    //padding-right: ($card-spacer-x / 2);
    //font-size: $default-font-size-sm;
    cursor: pointer;
    border-radius: $border-radius-xl $border-radius-xl 0 0;

    &::after {
      font-size: $default-icon-font-size;
      content: $icon_expand_more;
      cursor: pointer;
    }
  }

  &.is-closed {
    overflow: hidden;
  }

  //.no-admin-mode & {
  //  &.titleless {
  //    background-color: transparent;
  //    border: none;
  //
  //    > .card-body {
  //      padding: 0 !important;
  //    }
  //  }
  //}
  &.is-open {
    > .BaseWidget-handle {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}

.card-group-stacked {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: $card-group-margin;
  }

  .card {
    // Flexbugs #4: https://github.com/philipwalton/flexbugs#4-flex-shorthand-declarations-with-unitless-flex-basis-values-are-ignored
    flex: 1 1 auto;
    margin-bottom: $spacer;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.icon_hover_frame {
  position: absolute;
  z-index: 21;
  top: 0;
  left: 100%;
  overflow: hidden;
  max-width: 0;
  height: calc(100% - 2px);
  margin: 0;
  padding: 1px 0;
  transition: 0.15s ease-in-out;
  border: none;
  background-color: $black_70 !important;

  .ExpandCollapseButton {
    align-self: flex-end;
    margin: 0;

    &:before {
      content: "navigate_before";
      transition: none !important;
      transform: rotate(180deg);
    }

    &.collapsed:before {
      transform: rotate(0deg);
    }
  }

  &.collapsed {
    transition: 0.25s ease-in-out;
  }

  &.animate {
    max-width: 500px;
  }
}

.card-disclosure {
  &.is-closed {
    .actions-panel {
      display: none;
    }
  }

  &.is-open {
    .card-header {
      z-index: 4;
    }
  }

  .card-vertical-widget &,
  .card-group-flush & {
    font-size: $font-size-sm;
    border-width: 1px 0 0;

    .card-title {
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
    }

    .card-header {
      padding: ($spacer / 2) ($spacer * 0.75);
    }

    &.is-closed {
      .card-header {
        /* background-color: transparent;*/
        border: 0;

        &:hover {
          background-color: rgba($black, 0.03);
        }
      }
    }

    &.is-open {
      .card-header {
        .card-title {
          font-weight: bold !important;
        }
      }
    }
  }

  .card-vertical-widget & {
    border-right: 0;
    border-left: 0;
    @include border-radius(0);

    &:last-child {
      @include border-bottom-radius($list-group-border-radius);
    }

    .list-group-item {
      border-color: rgba($black, 0.1);
    }
  }

  .card-vertical-solr-widget & {
    .list-group-item {
      border: none;
    }
  }
}

.solr-value-panel:not(:empty) {
  & + .date-range-panel {
    border-top: $card-border-width solid $card-border-color;
  }
}

.sub-header-actions {
  margin-top: -1px;
  background-color: $card-cap-bg;
}

.card-group-flush {
  .card {
    border-right: 0;
    border-left: 0;
    @include border-radius(0);
  }

  //&:first-child {
  //  .card:first-child {
  //    border-top: 0;
  //  }
  //}
  //
  //&:last-child {
  //  .card:last-child {
  //    border-bottom: 0;
  //  }
  //}
}

.card-header-sm {
  .btn-icon {
    @include size(26px);

    .material-icon {
      transform: scale(1.35);
    }
  }
}

.card-expandable {
  > .card-header {
    &:first-child {
      transition: $btn-transition;
      background-color: $white;
      @include hover-focus {
        background-color: $card-cap-bg;
      }
    }
  }

  &.is-open {
    > .card-header {
      &:first-child {
        background-color: $white;
      }
    }
  }
}

.card-accordion {
  z-index: 1;
  overflow: hidden;
  margin-bottom: $spacer;
  border: none;
  @include mat-elevation(2);
  @include mat-elevation-transition();
  @include hover-focus {
    @include mat-elevation(5);
  }
  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    @include box-shadow(none);
  }

  &.active,
  &.is-open {
    @include mat-elevation(8);
  }

  .card-disclosure {
    border: none;

    &,
    > .card-header {
      border-radius: 0;
    }
  }

  &:not(.is-open) {
    .card-header {
      border-color: $white;
      background-color: $white;
    }
  }
}

.card-body {
  .list-group-flush {
    .list-group-item {
      &:only-child,
      &:first-child {
        border-top: none;
      }
    }
  }
}

.card-data-block {
  margin-bottom: ($spacer * 0.75);

  .card-title {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .card-header {
    padding: ($spacer / 3);
  }

  &.is-closed {
    .card-body {
      display: none;
    }

    .card-header {
      border: 0;

      &:hover {
        background-color: rgba($black, 0.03);
      }
    }
  }
}

.card-group-slat {
}

//
// Card Field-set
//
.card-fieldset {
  .modal-body & {
    &.is-closed {
      .card-header {
        &:not(.action-fieldset) {
          border: none;
        }
      }
    }
  }
}

.card-group-config {
  > .card-accordion,
  > .card-fieldset {
    border-width: 0 0 1px;
    @extend .wf-transition-active;

    &:not(.is-open) {
      border-radius: 0;

      &:first-child {
        border-radius:$border-radius-xl;
      }

      &:last-child {
        border-radius:$border-radius-xl;
        > .card-header {
          border: none;
        }
      }

      > .card-header {
        cursor: pointer;
        background-color: transparent;
      }

      &:hover {
        z-index: 1;

        > .card-header {
          background-color: rgba($black, 0.03);
        }
      }
    }

    &.is-open {
      margin-top: $spacer;
      margin-bottom: $spacer;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .card-fieldset {
      margin-bottom: $spacer;

      .card-title {
        font-weight: $font-weight-base;
      }

      > .card-header {
        cursor: pointer;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.is-closed {
        > .card-header {
          transition: $default-transition;
          @include hover-focus {
            background-color: rgba($black, 0.0875);
          }
        }
      }

      &.is-open {
      }
    }
  }
}
