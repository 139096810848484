//
// Images
//
// Let's target IE to respect aspect ratios and sizes for img tags containing SVG files
// https://gist.github.com/larrybotha/7881691
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%;
  }
}

.no-img-select {
  user-select: none;
  pointer-events: none;
  touch-action: none;
}

.dropdown-item-img {
  @include size(22px);
}

.avatar-sm {
  width: 24px;
  height: 24px !important;
}

.avatar-md {
  @include size(32px);
  min-width: 32px;
}

.avatar-lg {
  @include size(50px);
}

.iconHolder,
.icon-selection-panel {
  display: inline-flex;
  max-width: 50px !important;
  margin-left: 8px;
  cursor: pointer;

  &.mw-100 {
    max-width: 100px !important;
  }
}

.icon-selection-panel {
  &,
  &::after {
    @include transition($swift-ease-out);
  }

  &::after {
    @include absolute-centered($default-icon-font-size);
    content: $icon_camera_alt;
    transform: scale(0.9) translate(15px, 15px);
    opacity: 0.7;
    color: $white;
    text-shadow: 0 0 10px rgba($black, 0.8);
  }

  // Share hover and focus styles
  @include hover-focus {
    &::after {
      transform: scale(1.2) translate(0);
      opacity: 1;
    }
  }
}

.widget-icon {
  .embed-responsive {
    width: 85px;
  }
}

.media-widget-box-summary {
  .card-img {
    background-color: rgba($black, 0.03);

    .embed-responsive {
      max-width: 85px;
      margin: auto;
    }
  }

  &.active {
    .card-img {
      background-color: $white;
    }
  }
}

.thumbnail-icon {
  outline: none;

  .embed-responsive {
    transition: all $mat-elevation-transition-duration $mat-elevation-transition-timing-function;
    transform: scale(0.85);
    backface-visibility: hidden;
    @include hover {
      transform: scale(0.95);
      .btn {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  &.active {
    .embed-responsive {
      transform: scale(0.85);

      border: 4px solid theme-color("primary");
      outline: none;
      .embed-responsive-item {
        outline: none;
      }
    }
  }

  .btn {
    position: absolute;
    top: ($spacer / 4);
    right: ($spacer / 4);
    transition: $default-transition;
    transform: scale(0.5);
    opacity: 0;
    background-color: $white !important;
    box-shadow: 0 1px 6px rgba($black, 0.65);
    @include size(26px);
    backface-visibility: hidden;
  }
}

.processingImage {
  &::before {
    z-index: 2;
  }
}

.loading-dock-base-panel {
  .ConversionBadgePanel {
    position: absolute;
    bottom: 0;
  }
}

.object-fit-fill {
  @include object-fit(fill);
}

.object-fit-cover-child.Playing > video,
.object-fit-contain {
  font-family: "object-fit: contain" !important;
  object-fit: contain !important;
}

.object-fit-cover-child > img,
.object-fit-cover-child > video,
.object-fit-cover {
  @include object-fit(cover);
}

.object-fit-none {
  @include object-fit(none);
}

.object-fit-scale-down-child > img,
.object-fit-scale-down-child > video,
.object-fit-scale-down {
  font-family: "object-fit: scale-down" !important;
  object-fit: scale-down !important;
}

.embed-responsive-item-auto-size {
  right: 0;
  @include size(90% !important);
  margin: auto;
}

.lazyerror-parent {
  position: relative;

  .lazyerror {
    position: relative;
    border-color: transparent;
  }
}

.embed-responsive {
  .embed-responsive-item {
    position: absolute !important;
  }
}

.lazyerror {
  .processingImage & {
    visibility: hidden;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: " ";
    @include size(100%);
    border: 1px solid $body-bg;
    background-color: $body-bg;
  }

  &::after {
    font-style: normal;
    display: block;
    width: 100%;
    content: $icon_broken_image;
    text-align: center;
    opacity: 0.5;
    @include absolute-centered-y(1em);
  }
}

.image-container {
  position: relative;
  overflow: hidden;
  transform: translateZ(0);

  &:before {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    content: "";
  }
}

.object-position-x0 {
  object-position: 50% 0;
}

.object-position-x25 {
  object-position: 50% 25%;
}

.object-position-x75 {
  object-position: 50% 75%;
}

.object-position-x100 {
  object-position: 50% 100%;
}

.LDImageWrapper {
  position: absolute;
  left: 50%;
}

.dissolvingImage {
  transition: opacity $swift-ease-out-duration;

  &:not(.loadedImage) {
    opacity: 0.001;
  }

  .fieldContainer {
    position: absolute;
    z-index: 6;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 6px 0;
    transition: opacity 0.2s ease;
    opacity: 0;

    .standard_font {
      text-align: center;
      color: $white;
    }
  }

  > .fieldContainer {
    z-index: 20;
    align-self: flex-end;
    margin: 0;
    padding: 10px 0;
    opacity: 1;
    background: $black_40;
  }
}

.placedImageHolder {
  display: block;
}

.placedImage {
  width: 75px;
  height: 75px;

  img {
    margin-bottom: $spacer;
  }
}

.thumbnails-rtl-direction {
  float: right;
  margin: 5px 10px 0 5px !important;
}

.leadingImage {
  padding-left: 22px;
  background-repeat: no-repeat;
  background-position: 0;
}

.largeAssetDisplay {
  height: 75%;

  .selected {
    animation: blurNotify 0.25s linear;
    border: none;
    background-color: transparent;
  }
}

.smallAssetDisplay {
  overflow-x: auto;
  overflow-y: hidden;
  height: 180px;
}

.imageWrapper {
  text-align: center;
}

.imageContainer {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(0);
}

.assetImg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  padding: 4px;
  border: none !important;
  background-color: $pink;
}

.audioImage {
  @include size(100%);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
}

.iframeOverlay {
  position: absolute;
  z-index: 100000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.ratio-box {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 0 !important;
  padding: 0;
  // padding-bottom is calculated and rendered in to HTML
  .embed-responsive-item,
  img,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    @include size(100% !important);
    display: block;
    border: 0;
  }
}

//
// Icon Selection Panel
//
.IconSelectionPanel {
  position: relative;

  &:hover,
  &:focus {
    border-color: theme-color("primary");

    .icon-dropdown-overlay {
      visibility: visible;
    }
  }
}

.icon-dropdown-overlay {
  font-size: $font-size-lg;
  position: absolute;
  z-index: 1;
  top: $thumbnail-padding / 2;
  right: $thumbnail-padding / 2;
  visibility: hidden;
  background-color: $md-black-lighter;
}

//
// Grid Box
//
.gridbox {
  &:hover {
    .badgeHide,
    .MediaPlayButton {
      opacity: 1;
    }
  }

  .filenamecolumn .standard_font {
    margin-top: 4px;
  }
}

//
// Cropped Thumbnail
//
.CroppedThumbnail {
  position: relative;

  audio {
    width: 100%;
    height: auto;
  }
}

.loading-dock-asset {
  .InfiniteScrollBadges,
  .badge-conversion {
    position: absolute;
    z-index: 2;
  }

  .InfiniteScrollBadges {
    top: ($spacer / 3);
    right: ($spacer / 3);
  }

  .badge-conversion {
    bottom: ($spacer / 2);
    left: ($spacer / 2);
  }
}

.embed-responsive-3by2 {
  &::before {
    padding-top: percentage(2 / 3);
  }
}

.imageHolder {
  transition: all 0.3s;

  .AnimationPanel {
    display: flex;
    align-items: center;
    justify-content: center;
    @include size(calc(100% - 56px));
    margin: 28px;

    img {
      @include size(100% !important);
      object-fit: contain;
    }

    &:hover .MediaPlayButton {
      opacity: 1;
    }
  }

  .ThreeDimensionalPanel {
    .ThreeDimensionalPanelImage {
      @include size(100% !important, auto !important);
      max-width: 100%;
      max-height: 100%;
      background-size: contain;
    }

    .ThreeDimensionalPanel {
      @include size(100% !important);
    }

    &:hover > .MediaPlayButton {
      opacity: 1;
    }
  }

  .AudioPanel {
    @include size(calc(100% - 56px));
    margin: 28px;
  }

  > .ThreeDimensionalPanel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 60px) !important;
    height: calc(100% - 60px) !important;
    margin: 28px;
  }
}

.customImage {
  background-size: 18px 18px;
}
