//
// Organizer Modal and extensions - Workflow, theme, etc
//
.wf-visual-editor {
  height: 460px;
  margin-bottom: -5px;
}

.workflow-icon {
  line-height: 1;
  flex: 0 0 auto;
  padding: 2px;
  border: none;
  @include size($spacer * 1.5);
}

.workflow-drag-proxy {
  right: 5px;
  left: 5px !important;
}
