//
// Highlighter
//
@mixin highlighter-emphasis-variant($parent, $color) {
  .text-#{$parent} {
    color: $color !important;
    fill: $color !important;
  }
  .border-top-color-#{$parent} {
    border-top-color: $color !important;
  }
}

$highlighter-colors: ();
$highlighter-colors: map-merge(
  (
    "red": $red,
    "pink": $md-pink-a200,
    "purple": $purple,
    "indigo": $md-indigo-500,
    "blue": $blue,
    "cyan": $md-cyan-500,
    "teal": $md-teal-500,
    "green": $green,
    "lime": $md-lime-500,
    "yellow": $yellow,
    "orange": $orange,
    "brown": $md-brown-500,
    "gray": $gray-500,
    "blue-grey": $md-blue-grey-500,
  ),
  $highlighter-colors
);
@each $color, $value in $highlighter-colors {
  @include highlighter-emphasis-variant("#{$color}", $value);
}

.has-highlighter-svg {
  .highlighter-icon {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-width: 24px 24px 0 0;
    border-style: solid;
    border-color: transparent;
  }

  .x-grid3-row-table {
    .x-grid-cell-first {
      position: relative;
      overflow: hidden;

      .highlighter-icon {
        z-index: 1;
        border-width: 16px 16px 0 0;
      }
    }
  }

  .text-transparent {
    display: none;
  }

  &.card-grid-box {
    border-top-left-radius: $border-radius-sm;
  }

  &.infinite-scroll-item {
    .highlighter-icon {
      z-index: 2;
    }
  }
}
