//
// Navbar User Account BTN and Menu
//
.user-account-header {
}

.user-account-header-menu {
  .user-account-body {
    line-height: 1.3;
  }
}

.user-account-header-navbar {
  .user-account-body {
    padding-left: 0.75rem;
  }
}

.user-account-display-name {
  font-size: 90%;
}

.user-account-current-acl-name {
}
