// stylelint-disable selector-no-qualifying-type
//
// Input Groups
//
.input-group-page-count {
  .input-group-addon {
    background-color: transparent;
  }
}

//.input-group-trigger-field {
//  width: 100% !important;
//}
.input-group {
  &[style^="display: block"],
  &[style*=" display: block"],
  &[style^="display:block"],
  &[style*=" display:block"] {
    display: flex !important;
  }

  .sr-only + .form-control {
    @include border-left-radius($input-border-radius);
  }
}

.input-group-date {
  .form-control {
    padding-right: 0;
  }
}

.input-group-color-picker {
  .btn {
    &.disabled,
    &:disabled {
      &,
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }
}

.date-range-panel {
  .input-group-date,
  .int-range {
    &:first-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &:nth-child(2) {
      margin-left: -1px;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

.input-group-date {
  &.x-trigger-wrap-focus {
    // Bring the "active" form control to the top of surrounding elements
    z-index: 3;
  }
}

.input-group-trigger-field {
  //z-index: 1;
  border: $input-border-width solid $input-border-color;
  background-color: $input-bg;
  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @if $enable-rounded {
    // Manually use the if/else instead of the mixin to account for iOS override
    border-radius: $input-border-radius;
  } @else {
    // Otherwise undo the iOS default
    border-radius: 0;
  }
  @include transition($input-transition);

  .form-control,
  .btn,
  .input-group-addon {
    border: none !important;
  }

  &.x-form-invalid {
    border-color: $form-feedback-invalid-color;
  }

  &.x-trigger-wrap-focus {
    border-color: $input-focus-border-color;
  }
}

.input-group-empty {
  .input-group-btn-clear {
    .btn {
      position: relative;
      z-index: -1;
      opacity: 0;
    }
  }
}
.input-group-btn-clear {
  .ReadOnly & {
    display: none;
  }
}

.filterField {
  .x-form-trigger {
    .material-icon {
      @extend .text-hide;
    }

    &::before {
      content: $icon_close;
      transform: scale(1.5);

      html[dir="rtl"] & {
        transform: scaleX(-1) scale(1.5);
      }
    }
  }
}

.input-group-append {
  .btn-icon {
    transform: scale(0.9);
  }
}

//.input-group-sm {
//  .btn-icon {
//    line-height: $btn-pseudo-size-sm;
//    @include size($btn-pseudo-size-sm);
//  }
//}
.input-group-btn-absolute {
  position: relative;

  .form-control {
    padding-right: 2.75rem;
  }

  .btn {
    position: absolute;
    top: 3px;
    right: 6px;
  }
}

.input-time-spacer {
  font-size: $font-size-sm;
  line-height: $input-line-height-sm;
  padding: $input-padding-y-sm $input-padding-x-sm;
  border-right: 0;
  border-left: 0;
}
