//
// Search Panel - Advanced Search Modal
// base_widgets/client/searches/SearchPanel.java
//
@media (min-width: map-get($grid-breakpoints, "md")) {
  .modal-search-panel {
    .col-md-3.h-100 {
      position: absolute;
      top: 15px;
      right: 0;
      bottom: 15px;
      height: auto !important;
    }
  }
}

.SearchLabel {
  .flex3 {
    line-height: 38px;
  }
}
