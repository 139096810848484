//
// Photo Wall
//
$flexbin-row-height: 200px;
.photo-wall {
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  align-self: start;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100%;
  padding-bottom: $spacer;
  // Only include this when there is only 1 element because it messes up the flex calculation in infinite scroll on
  // on the last items on each 'page' as when a new page 'items' are added, the flex gets recalculated by the browser.
  &::after {
    flex-grow: 999999; // avoid final row zoom too much to fit width
    min-width: $flexbin-row-height; // avoid small gap in final row (< $row-height)
    height: 0;
    content: "";
  }
}

$photo-wall-ease-in-out: all 200ms $swift-ease-in-out-timing-function;
.photo-wall-item {
  position: relative;
  max-width: 100%;
  max-height: $flexbin-row-height;
  margin: ($spacer / 4);

  .ratio-box {
    position: static !important;
    max-height: $flexbin-row-height;
  }

  &.show {
    .ratio-box {
      overflow: visible;
      transform: none;
    }
  }
}

.photo-wall-item-container {
  &.has-highlighter-svg {
    .highlighter-svg {
      z-index: 2;
    }
  }

  //&::after {
  //  transition: $photo-wall-ease-in-out;
  //  content: "";
  //  @include mat-elevation(4);
  //  position: absolute;
  //  left: 0;
  //  right: 0;
  //  bottom: 0;
  //  top: 0;
  //  opacity: 0;
  //  z-index: -1;
  //  pointer-events: none;
  //  user-select: none;
  //}
}
