//
// Language Menu
//

//// Flag Mixin
@mixin flag-icon($country) {
	.flag-#{$country} {
		// inline - uses PostCss Assets to inline Base64 flags
		background-image: url("../flags/#{$country}.svg");
	}
}

.lang-icon {
	line-height: $spacer;
	position: relative;
	display: inline-block;
	width: (4 / 3) * $spacer;
	padding: 0;
	vertical-align: text-top;
	background: no-repeat 50%;
	background-size: cover;

	&::before {
		content: "\00a0";
	}
}

@include flag-icon(de);
@include flag-icon(en);
@include flag-icon(es);
@include flag-icon(fr);
@include flag-icon(it);
@include flag-icon(ja);
@include flag-icon(pt);
@include flag-icon(pt_br);
@include flag-icon(zh);
@include flag-icon(zh_tw);

.btn-language-fixed {
	.no-admin-mode & {
		position: absolute;
		z-index: 1005;
		top: $spacer;
		right: $spacer;
	}
}

.has-language-btn {
	.logoHolder {
		margin-top: $spacer * 3;
	}
}
