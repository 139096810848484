//
// Conversion Widget
//
.list-group-conversion {
  .dragdrop-positioner {
    width: 100%;
    background-color: transparent;

    > div {
      width: 100% !important;
      background-color: #e8f0fe;
      box-shadow: inset 2px 2px #4285f4, inset -2px -2px #4285f4, 0 1px 2px rgba(0, 0, 0, 0.1);
      @include border-radius($list-group-border-radius);
    }
  }
}

.conversion-config-panel {
  .dragdrop-movable-panel {
    .list-group-conversion-item {
      z-index: 2;
      width: 100%;
      margin-bottom: 0 !important;
      @extend .wf-5;
      @include border-radius($list-group-border-radius);
      padding: $list-group-item-padding-y / 2 $list-group-item-padding-x / 2;
      background-color: $list-group-bg;
    }
  }
}
