//
// Stamper Widget
//
.card-xmp-stamper-widget {
  .no-admin-mode & {
    &.titleless {
      .list-group-flush {
        > div:nth-last-child(2) {
          @include border-bottom-radius($card-border-radius);
        }
      }

      .list-group-item {
        &:first-child {
          @include border-top-radius($card-border-radius);
        }
      }
    }
  }

  .ui-xmp-stamps-panel {
    > div:not(:disabled):hover {
      background-color: rgba($black, 0.05);
    }
  }
}

.dropdown-menu-tippy {
  .ui-xmp-stamps-panel {
    > div:not(:disabled):hover {
      background-color: rgba($black, 0.05);
    }
  }
}
