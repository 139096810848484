// stylelint-disable selector-no-qualifying-type
//
// Transitions
//
.collapsed {
  .FullBadgeLabel,
  .full-badge-label,
  .TopFiveColorStrip {
    display: none;
  }
}

.maximize {
  .PreExtendedViewAssetInfoPanel {
    width: auto;

    .InfoBlock .value {
      max-height: none;
    }
  }

  .imageHolder {
    height: 100%;
  }

  .maximizeButton:before {
    content: "fullscreen_exit";
  }
}

.minimized {
  .x-masked-relative {
    display: none;
  }

  .BaseWidget-content,
  .BaseWidget-leftMiddle,
  .BaseWidget-rightMiddle,
  .BaseWidget-subheader,
  .BaseWidget-subheader-left,
  .BaseWidget-subheader-right,
  .BaseWidgetFooter,
  .BaseWidgetHeader,
  .LoadingDockNotificationWindow,
  .top {
    display: none;

    &,
    > * {
      pointer-events: none;
    }
  }

  .notification-bar {
    display: inherit;
  }

  .BaseWidget-title:before {
    transform: rotate(-90deg);
  }
}
