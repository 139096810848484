//
// Custom Radios
//
$radio-fill-color: theme-color("primary");
$radio-empty-color: $md-dark-icons-default;
$radio-border: 2px solid $radio-fill-color !default;
$radio-transition: border-color 0.2s $mat-fast-out-slow-in-timing-function,
  background-color 0.2s $mat-fast-out-slow-in-timing-function,
  box-shadow $swift-ease-out-duration $mat-fast-out-slow-in-timing-function;
// Remove default Radio Buttons
.mat-radio {
  position: relative;
  user-select: none;
  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not([disabled]):not(.disabled) {
    cursor: pointer;
  }

  &:not(.dropdown-item) {
    display: inline-flex;
    min-height: (1rem * $line-height-base);
    margin-right: $custom-control-spacer-x;
  }

  .custom-controls-stacked & {
    display: flex;
  }

  &.dropdown-item {
    margin-bottom: 0;

    .lever {
      display: block;
    }
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:not(:checked),
    &:checked {
      + span {
        position: relative;
        display: inline-flex;
        padding-left: $custom-control-spacer-x * 2;
        transition: $radio-transition;

        &::before,
        &::after {
          position: absolute;
          z-index: 0;
          top: (($line-height-base - 1.125rem) / 2);
          left: 0;
          @include size(1.125rem);
          display: block;
          content: "";
          user-select: none;
          transition: $radio-transition;
          pointer-events: none;
          border-radius: 50%;
          background-color: transparent;
        }
      }
    }

    &:not(:checked) {
      + span {
        &::before,
        &::after {
          border: 2px solid $radio-empty-color;
        }

        &::after {
          transform: scale(0);
        }
      }
    }

    &:checked {
      + span {
        &::before {
          // Checked styles
          border: 2px solid $radio-fill-color;
        }

        &::after {
          transform: scale(0.5);
          border: theme-color("primary");
          background-color: $radio-fill-color;
        }
      }
    }

    &:active {
      + span::before {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
      }
    }

    // Disabled Radio With gap
    &.disabled,
    &:disabled {
      &:active {
        + span::before {
          box-shadow: none;
        }
      }

      // $md-dark-icons-default: $md-black-dark;
      // $md-dark-icons-active: $md-black-darker;
      // $md-dark-icons-inactive: rgba($black, .38);
      + span {
        color: $text-muted;
      }

      &:checked {
        + span {
          &::before {
            opacity: 0.6;
          }

          &::after {
            opacity: 0.6;
          }
        }
      }

      //&:not(:checked),
      //&:checked {
      //  + span {
      //    &::before {
      //
      //      //border-color: $input-disabled-bg;
      //    }
      //  }
      //}
      &:not(:checked) + span::before {
        border-color: $md-dark-icons-inactive;
      }
    }
  }
}
