//
// Content Holder Widget
//
//.card-places-tree-widget {
//  .no-admin-mode & {
//    border-radius: 0;
//  }
//}
.PlacesTreeItem {
  .my-tree-item-text {
    padding-top: ($spacer / 2);
    padding-bottom: ($spacer / 2);
  }
}
.places-tree-menu-item {
  max-width: 220px;
  @include text-truncate;
}
.ui-dark-mode .card, .card {
	&.places-tree-tile-square {
		background-color: transparent;
		border: none;
	}
}

.places-tree-tile-square-holder {
  display: grid;
}

.places-tree-tile-columns-auto-fit {
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.places-tree-tile-columns-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.places-tree-tile {
  min-height: 200px;
  background-origin:content-box;
  color: white !important;
  font-size:0.75rem;
  border-radius: $border-radius-xl;
  @include media-breakpoint-up(md) {
    font-size:1.0rem;
  }
  @include media-breakpoint-up(lg) {
    font-size:1.5rem;
  }
  background-color: white !important;
  border-color: transparent !important;
}

.LandingPage {
  &.no-admin-mode .aside {
    .LayoutDropPanelCell {
      position: fixed !important;
      z-index: 5;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: $spacer ($spacer * 2) ($spacer * 2);
      @extend .overflow-hidden !optional;
      border: none;
      border-radius: 0;
      background-color: rgba($white, 0.5);

      .card-widget:not(.card-places-tree-widget):not(.card-workspace-widget) {
        display: none;
      }
    }

    &.hasWidget {
      z-index: 5;
    }

    .ui-places-tree-widget {
      border: none;
      background-color: transparent;
    }
  }
}
